import { isFloat } from "./utils";

const cellInfo = {
  field: "manage",
  headerName: "관리",
  flex: 1,
  cellClassName: "cells",
  headerClassName: "columnsHeader",
  headerAlign: "center",
  updatable: false,
};

export const userColumns = (arr, t, raiseRefresh) => {
  const newArr = [
    ...arr,
    {
      ...cellInfo,
      valueFormatter: ({ value }) => `${isFloat(value)}`,
      renderCell: (params) => {
        // return <UserManage params={params} raiseRefresh={raiseRefresh} />;
      },
    },
  ];
  return newArr;
};

export const ManageRows = (arr, t) => {
  const newArr = arr.map((item) => {
    const newRows = {
      ...item,
      manage: `${t("관리")}`,
    };
    return newRows;
  });
  return newArr;
};

export const columnFactory = (column, table) => {
  switch (column) {
    case "withdraw_per": {
      if (table === "config") {
        return true;
      } else {
        return false;
      }
    }
    case "admin_address": {
      return true;
    }
    case "price": {
      if (table === "package") {
        return true;
      } else {
        return false;
      }
    }
    case "up_line": {
      if (table === "package") {
        return true;
      } else {
        return false;
      }
    }
    case "down_line": {
      if (table === "package") {
        return true;
      } else {
        return false;
      }
    }
    case "recommend_count": {
      if (table === "package") {
        return true;
      } else {
        return false;
      }
    }
    case "withdraw_body": {
      if (table === "package") {
        return true;
      } else {
        return false;
      }
    }
    case "withdraw_ratio": {
      if (table === "package") {
        return true;
      } else {
        return false;
      }
    }
    case "percentage": {
      if (table === "recommend_percentage") {
        return true;
      } else {
        return false;
      }
    }
    case "is_withdraw": {
      return true;
    }
    default: {
      return false;
    }
  }
};
