import React from "react";
import GlobalContext from "context/GlobalContext";
import { Backdrop, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TransparentLoader = () => {
  const { t } = useTranslation();
  const { backdrop, setBackdrop } = React.useContext(GlobalContext);

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdrop}
      //   onClick={() => setBackdrop(null)}
    >
      <Box
        sx={{
          borderRadius: 5,
          width: 150,
          height: 150,
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>{t("로딩중...")}</Typography>
      </Box>
      {/* <CircularProgress color="inherit" /> */}
    </Backdrop>
  );
};

export default TransparentLoader;
