import React, { useContext, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import {
  InputAdornment,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Divider,
  Button,
  capitalize,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Dialog from "components/Common/Dialog";
import useDialog from "hooks/useDialog";
import { useSnackbar } from "notistack";

import Page from "components/Common/Page";
import { useTranslation } from "react-i18next";

import { useWeb3Auth } from "services/web3auth";
import { TextField } from "@mui/material";
import useInput from "hooks/useInput";
import { getBalance, runContract, generatePath, generateAddress } from "utils";
import GlobalContext from "context/GlobalContext";
import erc20Abi from "resources/abi/erc20Abi";
import { useLocation } from "react-router-dom";
import NetworkConfig from "components/Estimate/NetworkConfig";
import DrawerProvider from "components/Common/DrawerProvider";
import { chainName } from "utils";
import axios from "axios";

const LoginView = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { focused, provider, assets, rootKey } = React.useContext(GlobalContext);
  const { t } = useTranslation();
  const [balance, setBalance] = React.useState(null);
  // const [openDialog, dialogProps] = useDialog();
  // const [dialogMessage, setDialogMessage] = useState("");
  // const classes = useStyles();
  // const { logout } = useWeb3Auth();

  const [isValid, setIsValid] = React.useState(false);
  const [validSpinner, setValidSpinner] = React.useState(false);
  const [deployInfo, setDeployInfo] = React.useState(null);
  const [disabled, setDisabled] = React.useState(true);
  const [estimated, setEstimated] = React.useState(null);
  const [checked, setChecked] = React.useState("legacy");
  const [isOpen, setIsOpen] = React.useState(false);

  const emailInput = useInput("");
  const amountInput = useInput("");

  const onValidPress = async () => {
    setValidSpinner(true);
    if (
      amountInput.value === "" ||
      amountInput.value === undefined ||
      amountInput.value === null ||
      amountInput.value < 0 ||
      isNaN(Number(amountInput.value))
    ) {
      setValidSpinner(false);
      return enqueueSnackbar(t("올바른 갯수를 입력해주세요."), { variant: "error" });
    }
    if (balance < Number(amountInput.value)) {
      setValidSpinner(false);
      return enqueueSnackbar(t("잔액이 부족합니다."), { variant: "error" });
    }
    if (emailInput.value)
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_HOST}/user/checkaddr`, { email: emailInput.value });

        if (data.status) {
          if (emailInput.value.toLowerCase() === focused.walletAddress.toLowerCase()) {
            return enqueueSnackbar(t("내 지갑주소로 전송할 수 없습니다."), {
              variant: "error",
            });
          }

          setEstimated({ print: "0" });
          setIsOpen(true);
        } else {
          return enqueueSnackbar(t("올바른 지갑주소가 아니거나 원픽 월렛 회원 지갑주소가 아닙니다."), {
            variant: "error",
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => {
          setValidSpinner(false);
        }, 500);
      }
  };

  const reset = () => {
    emailInput.setValue("");
    amountInput.setValue("");
    setIsValid(false);
  };

  const setMax = async () => {
    if (balance) {
      amountInput.setValue(balance.toString());
    } else {
      const returnedBalance = await getPoint();
      amountInput.setValue(returnedBalance.toString());
    }
  };

  const onNextPress = () => {
    if (
      amountInput.value === "" ||
      amountInput.value === undefined ||
      amountInput.value === null ||
      amountInput.value < 0 ||
      isNaN(Number(amountInput.value))
    ) {
      return enqueueSnackbar(t("올바른 갯수를 입력해주세요."), { variant: "error" });
    }
    if (!provider[focused.based].utils.isAddress(emailInput.value)) {
      return enqueueSnackbar(t("올바른 지갑주소를 입력해주세요."), { variant: "error" });
    }

    setIsOpen(!isOpen);
  };

  const getPoint = async () => {
    const uuid = localStorage.getItem("wallet-uuid");
    const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/nonce`, { uuid });

    if (data.status) {
      if (focused.addressIndex < 0) {
        return null;
      }
      const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
      const { privateKey } = generateAddress(derived, focused.based);
      const signature = await provider[focused.based].eth.accounts.sign(
        provider[focused.based].utils.fromUtf8(`${data.nonce}`),
        privateKey
      );
      try {
        const { data: _data } = await axios.post(`${process.env.REACT_APP_HOST}/user/point`, {
          uuid,
          publicAddress: focused.walletAddress,
          signature,
        });

        setBalance(_data.result);
        return _data.result;
      } catch (e) {
        console.log(e);
        return 0;
      }
    }
  };

  React.useEffect(() => {
    getPoint();
  }, []);

  return (
    <Page
      goBack
      title="Transfer"
      header
      subtitle={`${assets.mainnet ? `[Mainnet] ` : `[Testnet] `} ${capitalize(chainName(focused.based))}`}
      invisibleRight
    >
      {!isNaN(Number(balance)) ? (
        <DrawerProvider
          isPoint={true}
          deployInfo={deployInfo}
          reset={reset}
          checked={checked}
          receive={location.state.contractAddress ? location.state.contractAddress : emailInput.value}
          amount={amountInput.value}
          estimated={estimated}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          {...location.state}
        >
          <Box style={{ position: "relative" }}>
            {focused.addressIndex < 0 && (
              <Box
                style={{
                  position: "absolute",
                  zIndex: 999,
                  backgroundColor: "rgba(255,255,255,0.6)",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                {t("직접 추가한 지갑주소로는 송금할 수 없습니다.")}
              </Box>
            )}
            <TextField
              sx={{ mb: 1 }}
              placeholder={t("받는 사람 지갑 주소 (0x...)")}
              fullWidth
              size="small"
              value={emailInput.value}
              onChange={emailInput.onChange}
            />
            <TextField
              fullWidth
              placeholder={t("송금할 갯수를 입력해주세요.")}
              size="small"
              value={amountInput.value}
              onChange={amountInput.onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ p: 0 }} onClick={setMax} onMouseDown={() => null}>
                      <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>{t("최대")}</Typography>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Divider sx={{ my: 2 }} />
            {!isValid && (
              <Box mt={1} display={"flex"} justifyContent="flex-end">
                <Button onClick={onValidPress} disabled={validSpinner}>
                  {validSpinner ? (
                    <CircularProgress size={15} />
                  ) : (
                    <Box display={"flex"} alignItems={"center"} lineHeight={1}>
                      {t("검증")}
                      <ChevronRightIcon fontSize="small" color={"primary"} />
                    </Box>
                  )}
                </Button>
              </Box>
            )}
          </Box>

          {isValid && (
            <React.Fragment>
              <NetworkConfig
                deployInfo={deployInfo}
                checked={checked}
                setChecked={setChecked}
                estimated={estimated}
                setEstimated={setEstimated}
                isOpen={isOpen}
                disabled={disabled}
                setDisabled={setDisabled}
              />
              <Divider />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Button onClick={reset}>
                  <Typography sx={{ lineHeight: 1, fontSize: 15 }}>{t("초기화")}</Typography>
                </Button>
                <Button onClick={onNextPress}>
                  <Typography sx={{ lineHeight: 1, fontSize: 15 }}>{t("다음")}</Typography>
                  <ChevronRightIcon />
                </Button>
              </Box>
            </React.Fragment>
          )}
        </DrawerProvider>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress size={15} />
        </Box>
      )}
    </Page>
  );
};

export default LoginView;
