import numeral from "numeral";

export const gridHeaderFactory = (column, table) => {
  switch (column) {
    case "id": {
      return "번호";
    }
    case "uuid": {
      return "유저 아이디";
    }
    case "method": {
      return "컨트랙트 기능";
    }
    case "input": {
      return "컨트랙트 값";
    }
    case "tx_hash": {
      return "트랜잭션 해쉬";
    }
    case "status": {
      return "상태";
    }
    case "message": {
      return "에러 메세지";
    }
    case "created_at": {
      return "생성일";
    }
    case "last_auth": {
      return "마지막 로그인";
    }
    case "main_address": {
      return "활성화된 지갑";
    }
    case "email": {
      return "이메일";
    }
    case "aggregate_verifier": {
      return "로그인 유형";
    }
    case "point": {
      return "포인트";
    }
    case "name": {
      return "소셜 유저네임";
    }
    case "is_admin": {
      return "어드민 여부";
    }
    default:
      return column;
  }
};

export const isFloat = (value) => {
  if (value === 0) {
    return value;
  }
  if (!value) {
    return "-";
  }
  if (!isNaN(Number(value)) && value.toString().substring(0, 2) !== "0x") {
    if (value % 1 === 0) {
      return numeral(value).format("0");
    } else {
      return numeral(value).format("0,0.0000");
    }
  } else {
    return value;
  }
};
