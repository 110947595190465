import React, { useContext } from "react";
import { Typography, Box, Card, IconButton, capitalize } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material";
import { ContentCopy, ScheduleRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { primary } from "styles/theme/colors";
import GlobalContext from "context/GlobalContext";
import ShareIcon from "@mui/icons-material/Share";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useSnackbar } from "notistack";
import { chainName } from "utils";
import { useHistory } from "react-router-dom";

const theme = createTheme({
  palette: { mode: "dark", primary },
  shape: {
    borderRadius: 10,
  },
  spacing: 10,
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", "sans-serif"].join(","),
    h6: {
      lineHeight: 1.2,
      fontWeight: 700,
    },
  },
  components: {
    MuiPaper: {
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: `linear-gradient(to right bottom, ${primary.main}, ${primary[400]} 110%)`,
            boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)",
          },
        },
      ],
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: "1px solid #fff",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          backgroundColor: "rgba(255,255,255,0.12)",
        },
        bar: {
          borderRadius: 10,
          backgroundColor: "#fff",
        },
      },
    },
  },
});

const AssetCard = ({ copyOnClick }) => {
  const { t } = useTranslation();
  const { focused } = useContext(GlobalContext);
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <ThemeProvider theme={theme}>
      <Card
        color="primary"
        variant="gradient"
        sx={{
          maxWidth: "100%",
          minHeight: 110,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 2.5,
        }}
      >
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ ml: 0.5, mt: "1px" }}>
              {/* {capitalize(chainName(focused.based))} Based */}
              {process.env.REACT_APP_PROJECT_NAME}
            </Typography>
            <Box display={"flex"} alignItems="center">
              {/* <IconButton
                size="small"
                onClick={async () => {
                  if (navigator.share) {
                    await navigator.share({ text: focused.based });
                  } else {
                    enqueueSnackbar(t("모바일에서만 지원되는 기능입니다."));
                  }
                }}
              >
                <ShareIcon fontSize={"small"} />
              </IconButton> */}
              <IconButton size="small" onClick={() => history.push("/qraddress")}>
                <QrCodeIcon fontSize={"small"} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", width: "80%", mt: 0.5 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: 140 }}
            >
              {focused.walletAddress}
            </Typography>
            <CopyToClipboard onCopy={copyOnClick} text={focused.walletAddress}>
              <IconButton id="copybtn">
                <ContentCopy sx={{ fontSize: 15 }} />
              </IconButton>
            </CopyToClipboard>
          </Box>
        </Box>
        <Box textAlign="right">
          <Typography variant="h6" component="div"></Typography>
        </Box>
        {/* <Box sx={{ display: "flex", alignItems: "center", mb: -0.5, mt: 0.5 }}>
        <LinearProgress variant="determinate" value={60} sx={{ flexGrow: 1 }} />
        <Typography color="#00C8FF" variant="body2" sx={{ ml: 2 }}>
          <b>60%</b>
        </Typography>
      </Box> */}
      </Card>
    </ThemeProvider>
  );
};

export default AssetCard;
