import React from "react";
import GlobalContext from "context/GlobalContext";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "Routes";

import { Web3AuthProvider } from "services/web3auth";
import { defaultFocused, defaultAsset } from "utils/constants";
import web3Factory from "utils/web3Factory";
import { SnackbarProvider } from "notistack";
import TransparentLoader from "components/Common/TransparentLoader";

const App = () => {
  const [web3AuthNetwork, setWeb3AuthNetwork] = React.useState("mainnet");
  const [chain, setChain] = React.useState("mainnet");

  const [backdrop, setBackdrop] = React.useState(false);
  const [bottomNav, setBottomNav] = React.useState("wallet");
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [rootKey, setRootKey] = React.useState(null);
  const [focused, setFocused] = React.useState(defaultFocused);
  const [assets, setAssets] = React.useState(defaultAsset);
  const [seed, setSeed] = React.useState(null);

  const [isConnected, setIsConnected] = React.useState(false);

  const contextValue = {
    bottomNav,
    setBottomNav,
    dialogMessage,
    setDialogMessage,
    focused,
    setFocused,
    assets,
    setAssets,
    rootKey,
    setRootKey,
    seed,
    setSeed,
    backdrop,
    setBackdrop,
    provider: web3Factory,
    isConnected,
    setIsConnected,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      <SnackbarProvider
        autoHideDuration={1500}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        maxSnack={3}
      >
        <TransparentLoader />
        <Router>
          <Web3AuthProvider chain={chain} web3AuthNetwork={web3AuthNetwork}>
            <Routes />
          </Web3AuthProvider>
        </Router>
      </SnackbarProvider>
    </GlobalContext.Provider>
  );
};

export default App;
