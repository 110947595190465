import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { useWeb3Auth } from "services/web3auth";
import { isSafari } from "react-device-detect";

import Page from "components/Common/Page";
import Logo from "resources/logo.png";
import GlobalContext from "context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    width: "100%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(7),
  },
}));

const LoginView = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { isConnected, setIsConnected } = React.useContext(GlobalContext);
  const { loginExternal, logout } = useWeb3Auth();
  const [isClicked, setIsClicked] = React.useState(false);

  React.useEffect(() => {
    if (!isSafari) {
      (async () => {
        await loginExternal();
      })();
    }
  }, []);
  return (
    <Page
      isExternalLogin={true}
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      className={classes.root}
      title="Login"
      bottomTab={false}
      pb={1}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
         
        <Button
          onClick={() => {
            loginExternal();
            setTimeout(() => {
              setIsClicked(true);
            }, 2000);
          }}
          style={{ width: 200, height: 80, fontSize: 20 }}
          variant="contained"
          size="large"
        >
          {t("로그인")}
        </Button>
        {isClicked && (
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
            <Button
              onClick={() => {
                window.focus();
                window.close();
              }}
              style={{ width: 200, height: 80, fontSize: 20 }}
              variant="outlined"
              size="large"
            >
              {t("창 닫기")}
            </Button>
          </Box>
        )}
      </Box>
      {/* {isSafari && (
        <Button
          onClick={loginExternal}
          style={{ width: 200, height: 80, fontSize: 20 }}
          variant="contained"
          size="large"
        >
          {t("로그인")}
        </Button>
      )} */}
    </Page>
  );
};

export default LoginView;
