import React, { useContext, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import QRCode from "qrcode.react";
import { ContentCopy } from "@mui/icons-material";

import useDialog from "hooks/useDialog";
import Dialog from "components/Common/Dialog";

import Page from "components/Common/Page";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useWeb3Auth } from "services/web3auth";
import GlobalContext from "context/GlobalContext";
import { capitalize, IconButton, Typography, Box } from "@mui/material";
import { chainName } from "utils";
import useInput from "hooks/useInput";
import { useSnackbar } from "notistack";

const useclassNames = makeStyles((theme) => {
  return {};
});

const AddAssetView = () => {
  const classes = useclassNames();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, dialogProps] = useDialog();
  const [dialogMessage, setDialogMessage] = React.useState("");

  const { t } = useTranslation();
  const { provider, focused, setFocused, assets, rootKey } = React.useContext(GlobalContext);

  const copyOnClick = () => {
    setDialogMessage(t("클립보드에 복사되었습니다."));
    openDialog();
    document.getElementById("copybtn2").click();
  };
  return (
    <Page
      goBack
      title="QR Code"
      header
      subtitle={`${assets.mainnet ? `[Mainnet] ` : `[Testnet] `} ${capitalize(chainName(focused.based))}`}
    >
      <Dialog {...dialogProps} title={t("알림")}>
        {dialogMessage}
      </Dialog>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <QRCode size={300} value={focused.walletAddress} />
        <Box sx={{ wordBreak: "break-all" }}>
          <Typography mt={4} variant="h3">
            {focused.walletAddress}
            <CopyToClipboard onCopy={copyOnClick} text={focused.walletAddress}>
              <IconButton id="copybtn2">
                <ContentCopy sx={{ fontSize: 16 }} />
              </IconButton>
            </CopyToClipboard>
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};

export default AddAssetView;
