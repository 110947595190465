import React from "react";

import EmptyIcon from "resources/image/icon_empty.png";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Typography, Box, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PanoramaIcon from "@mui/icons-material/Panorama";

const AssetRow = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.rowWrapper}>
      <Box className={classes.row}>
        {props.loading ? (
          <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={15} color="primary" />
          </Box>
        ) : (
          <Box className={classes.leftWrapper}>
            <Box sx={{ display: "flex", width: "75%" }}>
              {props.nft ? (
                <Box className={classes.circle}>
                  <PanoramaIcon sx={{ color: "#BAC3D1" }} />
                  <Typography fontSize={9} variant="caption">
                    {"NFT"}
                  </Typography>
                </Box>
              ) : (
                <Box className={classes.circle}>
                  <img className={classes.icon} src={props.icon ? props.icon : EmptyIcon} />
                </Box>
              )}

              <Box className={classes.leftInner}>
                <Typography className={classes.ticker}>
                  {props.name} ({props.ticker && props.ticker.toUpperCase()})
                </Typography>
                <Typography className={classes.text}>{props.address}</Typography>
              </Box>
            </Box>
            <Box>
              <IconButton onClick={props.onPress}>
                <AddCircleOutlineIcon sx={{ fontSize: 26 }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  ticker: {
    fontSize: 17,
    lineHeight: 1.25,
  },
  leftInner: {
    width: "100%",
    marginLeft: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  leftWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 10,
    paddingLeft: 10,
    height: "100%",
    width: "100%",
  },
  text: {
    lineHeight: 1,
    maxWidth: "100%",
    color: "grey",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 14,
  },
  circle: {
    borderRadius: 18,
    padding: 6,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
  },
  rowWrapper: {
    marginTop: 15,
    backgroundColor: theme.palette.backgroundColor,
    width: "100%",
    boxShadow: "0px 0px 7.5px 1px rgba(0,0,0,0.3)",
  },
  row: {
    alignItems: "center",
    height: 60,
    width: "100%",
  },
}));

export default AssetRow;
