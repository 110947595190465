import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { getPrice } from "utils";
import { CircularProgress, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GlobalContext from "context/GlobalContext";

const Container = ({ disabled, setDisabled, isOpen, setEstimated, estimated, deployInfo }) => {
  const { t } = useTranslation();
  const { focused, provider, assets } = React.useContext(GlobalContext);
  const [dollar, setDollar] = React.useState(null);
  const [timer, setTimer] = React.useState(30);
  const interval = useRef();
  const classes = useStyles();

  const getGas = async () => {
    setDisabled(true);
    const gas = deployInfo.limit;
    const gasPrice = await provider[focused.based].eth.getGasPrice();
    const print = Number(provider[focused.based].utils.fromWei(gasPrice)) * gas;
    const price = await getPrice(focused.based);
    setDollar((Number(price) * Number(print)).toString());
    setEstimated({
      print: print.toFixed(8),
      gas,
      gasPrice: provider[focused.based].utils.toHex(gasPrice),
    });
    setDisabled(false);
  };

  React.useEffect(() => {
    if (isOpen) {
      clearInterval(interval.current);
      interval.current = null;
    } else {
      interval.current = setInterval(() => {
        setTimer((time) => time - 1);
      }, 1000);
    }
  }, [isOpen]);

  React.useEffect(() => {
    getGas();
  }, []);

  React.useEffect(() => {
    if (timer === 0) {
      setTimeout(() => {
        setTimer(30);
        getGas();
      }, 500);
    }
  }, [timer]);

  return (
    <Box className={classes.row}>
      <Typography>{t("예상 가스 수수료")}</Typography>
      {estimated && dollar ? (
        <Box className={classes.refreshWrapper}>
          <Box className={classes.gasWrapper}>
            {!disabled && (
              <React.Fragment>
                <Typography>
                  {estimated.print} {assets.ticker.toUpperCase()}
                </Typography>
                <Typography color="grey" sx={{ fontSize: 13, lineHeight: 1 }}>
                  {Number(dollar).toFixed(2)} $
                </Typography>
              </React.Fragment>
            )}
          </Box>
          {disabled ? (
            <CircularProgress size={15} color="grey" />
          ) : (
            <Box>
              <LocalGasStationIcon size={24} color={"primary"} />
              <Typography className={classes.timer}>{timer}s</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <CircularProgress size={15} sx={{ mr: 1 }} color="grey" />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  timer: {
    fontSize: 11,
    color: "black",
    textAlign: "center",
  },
  refreshWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  gasWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: 5,
  },
  container: {
    width: "100%",
  },
  row: {
    display: "flex",
    minHeight: 75,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingVertical: 15,
    paddingHorizontal: 5,
  },
}));

export default Container;
