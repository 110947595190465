import React from "react";
import { AppBar, Toolbar, Container, Box, IconButton, Typography } from "@mui/material";
import { ArrowBack, Menu, CropFree, Public, Refresh } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const HeaderTitle = ({ title, subtitle }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography>{title}</Typography>
      {subtitle && (
        <Typography lineHeight={0.8} color="primary" variant="caption">
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

const Header = ({ network, refresh, goBack, menu, qr, title, subtitle, invisible, invisibleRight }) => {
  const history = useHistory();

  const backButton = () => {
    history.goBack();
  };

  return (
    <Container maxWidth="xs">
      <AppBar position="fixed" color="inherit" sx={{ boxShadow: 0 }}>
        <Toolbar sx={{ px: 1 }}>
          <Container maxWidth="xs" sx={{ display: "flex", padding: 0, justifyContent: "space-between" }}>
            <Box>
              {invisible && <Box sx={{ width: 40, height: 40 }}></Box>}
              {network && (
                <IconButton onClick={() => history.push("/networks")}>
                  <Public />
                </IconButton>
              )}
              {goBack && (
                <IconButton onClick={backButton}>
                  <ArrowBack />
                </IconButton>
              )}
              {menu && (
                <IconButton onClick={backButton}>
                  <Menu />
                </IconButton>
              )}
            </Box>
            <HeaderTitle title={title} subtitle={subtitle} />
            <Box>
              {qr && (
                <IconButton onClick={backButton}>
                  <CropFree />
                </IconButton>
              )}
              {refresh && (
                <IconButton onClick={refresh}>
                  <Refresh />
                </IconButton>
              )}
              {invisibleRight && <Box sx={{ width: 40, height: 40 }}></Box>}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default Header;
