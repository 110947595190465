import React from "react";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import { Box, Typography, Divider, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Eip1559 from "components/Estimate/Eip1559";
import Legacy from "components/Estimate/Legacy";

const Container = ({ disabled, setDisabled, isOpen, setEstimated, estimated, checked, setChecked, deployInfo }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { assets } = React.useContext(GlobalContext);
  return (
    <Box className={classes.container}>
      <Box className={classes.typeWrapper}>
        <Typography>{t("가스(수수료) 산정 방식")}</Typography>
        <Box className={classes.selectWrapper}>
          {["legacy", "eip1559"].map((item, index) => {
            return (
              <FormControlLabel
                key={"label" + index}
                label={item.toUpperCase()}
                control={
                  <Checkbox disabled={!assets.fee[item]} checked={checked === item} onClick={() => setChecked(item)} />
                }
              ></FormControlLabel>
            );
          })}
        </Box>
      </Box>
      <Divider style={{ marginTop: 5 }} />
      {checked === "legacy" && (
        <Legacy
          deployInfo={deployInfo}
          disabled={disabled}
          setDisabled={setDisabled}
          isOpen={isOpen}
          setEstimated={setEstimated}
          estimated={estimated}
        />
      )}
      {checked === "eip1559" && (
        <Eip1559
          deployInfo={deployInfo}
          disabled={disabled}
          setDisabled={setDisabled}
          isOpen={isOpen}
          setEstimated={setEstimated}
          estimated={estimated}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  selectWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  typeWrapper: {
    paddingVertical: 15,
    paddingHorizontal: 5,
  },
  radio: {
    // backgroundColor: theme.colors.backgroundColor,
    width: 150,
    borderWidth: 0,
    padding: 0,
    margin: 0,
  },
  timer: {
    fontSize: 11,
    color: "black",
    textAlign: "center",
  },
  refreshWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  gasWrapper: {
    display: "flex",
    alignItems: "flex-end",
    marginRight: 5,
  },
  container: {
    width: "100%",
  },
  row: {
    minHeight: 75,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingVertical: 15,
    paddingHorizontal: 5,
  },
}));

export default Container;
