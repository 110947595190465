export const primary = {
  50: "#fee8e6",
  100: "#ffc9b7",
  200: "#ffa589",
  300: "#ff7f59",
  400: "#ff6033",
  500: "#ff3e04",
  main: "#ff3900", // contrast 3.83:1
  600: "#ff3700",
  700: "#f12f00",
  800: "#e32500",
  900: "#cc0900",
};
