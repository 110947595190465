import React from "react";
import AdminPage from "./AdminPage";
import { useEffectOnce } from "react-use";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Paper,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Container,
} from "@mui/material";
import { generatePath, generateAddress } from "utils";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { useSnackbar } from "notistack";
import { isFloat, gridHeaderFactory } from "./utils";
import { columnFactory } from "./factory";
import { useLocation } from "react-router-dom";
import GlobalContext from "context/GlobalContext";

const Admin = () => {
  const { focused, rootKey, assets, provider } = React.useContext(GlobalContext);
  const location = useLocation();
  const [init, setInit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [columns, setColumns] = React.useState(null);
  const [rows, setRows] = React.useState(null);
  const [rowCount, setRowCount] = React.useState(null);
  const [cellValue, setCellValue] = React.useState(null);
  const [cellLabel, setCellLabel] = React.useState(null);
  const [cellId, setCellId] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [selectColumn, setSelectColumn] = React.useState("id");
  const [selectPages, setSelectPages] = React.useState("1");
  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [isCreatedAt, setIsCreatedAt] = React.useState(true);
  const [isHome, setIsHome] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const smView = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const getProps = async () => {
    const uuid = localStorage.getItem("wallet-uuid");
    const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/nonce`, { uuid });

    if (data.status) {
      const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
      const { privateKey } = generateAddress(derived, focused.based);
      const signature = await provider[focused.based].eth.accounts.sign(
        provider[focused.based].utils.fromUtf8(`${data.nonce}`),
        privateKey
      );
      try {
        return {
          uuid,
          publicAddress: focused.walletAddress,
          signature,
        };
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  };

  const errorCase = (code) => {
    switch (code) {
      case "invalid table": {
        return enqueueSnackbar(t("어드민 메인 페이지 입니다."), { variant: "info" });
      }
      case "invalid column": {
        return enqueueSnackbar(t("컬럼이 선택되지 않았습니다."), { variant: "info" });
      }
      case "invalid pages": {
        return enqueueSnackbar(t("페이지를 올바르게 입력해주세요."), { variant: "info" });
      }
      case "disable update column": {
        return enqueueSnackbar(t("선택하신 셀은 변경할 수 없습니다."), { variant: "info" });
      }
      default: {
        return enqueueSnackbar(t("서버 오류입니다. 잠시 후에 다시 시도해주세요."), { variant: "error" });
      }
    }
  };

  const onCopy = () => {
    enqueueSnackbar(t("클립보드로 복사되었습니다."), { variant: "info" });
  };

  const raiseRefresh = () => {
    setRefresh(!refresh);
  };

  useEffectOnce(() => {
    (async () => {
      try {
        const splitTable = location.pathname.split("/admin/")[1];
        if (splitTable === undefined) {
          setIsHome(true);
          return () => null;
        }
        const cert = await getProps();

        const body = { init, table: splitTable, ...cert };
        const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/log", body);
        const { result } = data;

        if (data.status) {
          const newColumns = await result.columns.map((item, index) => {
            const columnsObject = {
              field: item.Field,
              headerName: gridHeaderFactory(item.Field),
              flex: 1,
              cellClassName: "cells",
              headerClassName: "columnsHeader",
              headerAlign: "center",
              updatable: item.updatable,
              renderCell: (params) => {
                let value = params.value;
                if (params.colDef.valueFormatter(params)) {
                  value = params.colDef.valueFormatter(params);
                }

                return params.colDef.updatable ? (
                  <div key={index + "1"} className={classes.renderCellDiv}>
                    <span className={classes.renderCellSpan}>{value}</span>
                  </div>
                ) : (
                  <div key={index + "1"} className={classes.renderCellDiv}>
                    <CopyToClipboard text={value} onCopy={onCopy}>
                      <span className={classes.renderCellSpan}>{value}</span>
                    </CopyToClipboard>
                  </div>
                );
              },
              valueFormatter: (params) => {
                if (params.field === "created_at" || params.field === "last_auth") {
                  const newValue = moment(params.value).format("YYYY-MM-DD HH:mm:ss");
                  return newValue;
                } else {
                  return isFloat(params.value);
                }
              },
            };

            return columnsObject;
          });

          const checkDate = newColumns.filter((item) => {
            const checkArr = item.field === "created_at";
            return checkArr;
          });
          if (checkDate[0]) {
            setIsCreatedAt(true);
          } else {
            setIsCreatedAt(false);
          }

          setRowCount(result.count);
          setColumns(newColumns);
          setRows(result.rows);
        } else {
          errorCase(data.code);
        }
      } catch (e) {
        console.log(e);
        errorCase(e.message);
      } finally {
        setInit(false);
      }
    })();
  });

  const paginationFunction = React.useCallback(async () => {
    if (!init) {
      setLoading(true);
      try {
        const splitTable = location.pathname.split("/admin/")[1];
        const cert = await getProps();
        const body = {
          table: splitTable,
          page: page,
          perPage: perPage,
          selectColumn: selectColumn,
          searchText: searchText,
          startDate: startDate,
          endDate: endDate,
          ...cert,
        };
        const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/log", body);
        const { result } = data;

        if (data.status) {
          setRows(result.rows);
          setRowCount(result.count);
        } else {
          errorCase(data.code);
        }
      } catch (e) {
        errorCase(e.message);
      } finally {
        setLoading(false);
      }
    }
    //eslint-disable-next-line
  }, [page, perPage, refresh]);

  React.useEffect(() => {
    paginationFunction();
  }, [paginationFunction]);

  const searchOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const splitTable = location.pathname.split("/admin/")[1];
      const cert = await getProps();

      const body = {
        table: splitTable,
        searchText: searchText,
        selectColumn: selectColumn,
        startDate: startDate,
        endDate: endDate,
        ...cert,
      };

      const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/log", body);
      const { result } = data;

      if (data.status) {
        setRows(result.rows);
        setRowCount(result.count);
      } else {
        errorCase(data.code);
      }
    } catch (e) {
      errorCase(e.message);
    } finally {
      setLoading(false);
    }
  };

  const dateOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const splitTable = location.pathname.split("/admin/")[1];
      const cert = await getProps();

      const body = {
        table: splitTable,
        searchText: searchText,
        selectColumn: selectColumn,
        startDate: startDate,
        endDate: endDate,
        ...cert,
      };
      const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/log", body);
      const { result } = data;

      if (data.status) {
        setRows(result.rows);
        setRowCount(result.count);
      } else {
        errorCase(data.code);
      }
    } catch (e) {
      errorCase(e.message);
    } finally {
      setLoading(false);
    }
  };

  const pageOnSubmit = async (e) => {
    e.preventDefault();
    setPage(selectPages <= 0 ? 0 : selectPages - 1);
  };

  const onCellClick = async (props) => {
    const splitTable = location.pathname.split("/admin/")[1];
    const status = columnFactory(props.field, splitTable);
    if (status) {
      setCellLabel(props.field);
      setCellValue(props.value);
      setCellId(props.id);
      setOpen(true);
    } else {
      return () => null;
    }
  };

  const dialogConfirm = async () => {
    setLoading(true);
    const splitTable = location.pathname.split("/admin/")[1];
    try {
      const body = {
        column: cellLabel,
        row: cellValue,
        id: cellId,
        table: splitTable,
      };
      const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/update", body);
      if (data.status) {
        setOpen(false);
        raiseRefresh();
        return enqueueSnackbar(t("수정이 완료되었습니다."), { variant: "info" });
      }
    } catch (e) {
      console.log(e);
      errorCase(e.message);
    } finally {
      setLoading(false);
    }
  };

  const dialogCancel = () => {
    setOpen(false);
  };

  return (
    <AdminPage header title={"Admin"}>
      <Paper style={{ paddingLeft: smView ? 0 : 240 }} className={classes.paper}>
        <Dialog open={open} onClose={dialogCancel}>
          <DialogTitle style={{ width: 400, marginTop: 10, fontSize: 17, fontWeight: 600 }}>
            {t("수정하기")}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={cellLabel}
              fullWidth={true}
              variant="standard"
              value={cellValue}
              onChange={(e) => {
                setCellValue(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions style={{ marginBottom: 5, marginTop: -5 }}>
            <Button onClick={dialogCancel}>{t("취소")}</Button>
            <Button onClick={dialogConfirm}>{t("확인")}</Button>
          </DialogActions>
        </Dialog>

        <div>
          {!isHome && (
            <div className={classes.divContainer}>
              <form onSubmit={searchOnSubmit} className={classes.form}>
                <FormControl variant="standard" style={{ width: 100 }}>
                  <InputLabel id="label">{t("컬럼")}</InputLabel>
                  {columns && (
                    <Select
                      id="select"
                      labelId="label"
                      value={selectColumn}
                      defaultValue={selectColumn}
                      label="컬럼"
                      onChange={(e) => setSelectColumn(e.target.value)}
                    >
                      {columns.map((item, index) => {
                        return (
                          <MenuItem key={"search" + index} value={item.field}>
                            {item.headerName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
                <TextField
                  style={{ marginTop: 13, marginLeft: 20, width: 150 }}
                  variant="standard"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search…"
                />

                <Button type="submit" variant="contained" style={{ marginLeft: 15, marginTop: 11 }}>
                  {t("검색")}
                </Button>
              </form>

              {isCreatedAt && (
                <form className={classes.form} onSubmit={dateOnSubmit}>
                  <div style={{ display: "inline-block", marginRight: 20, width: 160 }}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        label="시작 날짜"
                        value={startDate}
                        onChange={(value) => {
                          setStartDate(moment(value).format("YYYY-MM-DD"));
                        }}
                        inputFormat={"yyyy-MM-dd"}
                        mask={"____-__-__"}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>

                  <div style={{ display: "inline-block", marginRight: 15, width: 160 }}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        type
                        label="끝 날짜"
                        value={endDate}
                        onChange={(value) => {
                          setEndDate(moment(value).format("YYYY-MM-DD"));
                        }}
                        inputFormat={"yyyy-MM-dd"}
                        mask={"____-__-__"}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <Button type="submit" variant="contained" style={{ marginTop: 11 }}>
                      {t("검색")}
                    </Button>
                  </div>
                </form>
              )}

              <form className={classes.form} onSubmit={pageOnSubmit}>
                <TextField
                  label={t("페이지")}
                  style={{ marginTop: 0, marginRight: 10, textAlign: "center" }}
                  variant="standard"
                  value={selectPages}
                  onChange={(e) => setSelectPages(e.target.value)}
                  placeholder="pages..."
                />
                <div style={{ display: "inline-block" }}>
                  <Button type="submit" variant="contained" style={{ marginLeft: 10, marginTop: 11 }}>
                    {t("이동")}
                  </Button>
                </div>
              </form>
            </div>
          )}

          <div style={{ height: "83vh", width: "100%" }} className={classes.container}>
            {columns && rows && !isNaN(Number(rowCount)) ? (
              <>
                <DataGrid
                  page={page}
                  onPageChange={(newPage) => {
                    setPage(newPage);
                  }}
                  pageSize={perPage}
                  onPageSizeChange={(newSize) => {
                    setPerPage(newSize);
                  }}
                  loading={loading}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  pagination
                  paginationMode="server"
                  columns={columns}
                  rows={rows}
                  rowCount={rowCount}
                  onCellClick={(props) => {
                    if (props.field !== "manage") {
                      onCellClick(props);
                    }
                  }}
                  disableSelectionOnClick={true}
                  disableColumnMenu={true}
                  components={{
                    ColumnSortedAscendingIcon: null,
                    ColumnSortedDescendingIcon: null,
                    ColumnUnsortedIcon: null,
                  }}
                  getCellClassName={(params) => {
                    if (params.colDef.updatable === true) {
                      return "unUpdateCells";
                    }
                    return "cells";
                  }}
                />
              </>
            ) : (
              !isHome && (
                <Container maxWidth="xs" className={classes.loadingContainer}>
                  <CircularProgress size={15} />
                </Container>
              )
            )}
          </div>
        </div>
      </Paper>
    </AdminPage>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    paper: {},
    renderCellDiv: { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" },
    renderCellSpan: {
      textAlign: "center",
      width: "100%",
      height: "100%",
    },
    searchButton: {
      marginLeft: 10,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 9,
      paddingBottom: 9,
      fontSize: 13,
      fontWeight: 600,
      color: "white",
      backgroundColor: "#3849AB",
      borderRadius: 5,
    },
    divContainer: {
      paddingTop: 8,
      paddingBottom: 7,
      paddingLeft: 25,
      paddingRight: 25,
      display: "flex",
      justifyContent: "space-between",
      alignContent: "center",
      flexWrap: "wrap",
    },
    form: {
      height: 70,
      display: "flex",
      alignItems: "center",
    },
    loadingContainer: {
      height: "83vh",
      width: "100%",
      padding: 15,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    container: {
      paddingRight: 1,
      "& .columnsHeader": {},
      "& .cells": {
        width: "100%",
        height: "fit-content",
        overflow: "auto",
        textOverflow: "revert",
        textAlign: "center",
      },
      "& .unUpdateCells": {
        width: "100%",
        overflow: "auto",
        textOverflow: "revert",
        textAlign: "center",
        backgroundColor: "#F1F0FF",
      },

      "& .MuiDataGrid-columnHeaderTitle": {
        textOverflow: "revert",
        overflow: "auto",
      },
    },
  };
});

export default Admin;
