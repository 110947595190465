import { ADAPTER_EVENTS, SafeEventEmitterProvider, WALLET_ADAPTERS } from "@web3auth/base";
// import { Web3Auth } from "@web3auth/web3auth";
import { Web3Auth } from "@web3auth/modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { hdkey } from "ethereumjs-wallet";
import unorm from "unorm";
import crypto from "crypto-browserify";

import { isSafari } from "react-device-detect";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { CHAIN_CONFIG, CHAIN_CONFIG_TYPE } from "../config/chainConfig";
import { WEB3AUTH_NETWORK_TYPE } from "../config/web3AuthNetwork";
import { getWalletProvider, IWalletProvider } from "./walletProvider";
import { generatePath, generateAddress } from "utils";
import { defaultAsset, defaultFocused } from "utils/constants";
import GlobalContext from "context/GlobalContext";
import ICON from "resources/icon.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Web3AuthContext = createContext({
  web3Auth: null,
  provider: null,
  isLoading: false,
  user: null,
  setUser: null,
  chain: "",
  login: async () => {},
  loginExternal: async () => {},
  logout: async () => {},
  getUserInfo: async () => {},
  signMessage: async () => {},
  getAccounts: async () => {},
  getBalance: async () => {},
  signTransaction: async () => {},
  signAndSendTransaction: async () => {},
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}

export const Web3AuthProvider = ({ children, web3AuthNetwork, chain }) => {
  const { t } = useTranslation();

  const [web3Auth, setWeb3Auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [password, setPassword] = useState(null);
  const [tempInfo, setTempInfo] = useState(null);
  const [toggleLogin, setToggleLogin] = useState(false);
  const [recommend, setRecommend] = useState("");
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setFocused, setSeed, setRootKey, setAssets, provider: _provider } = useContext(GlobalContext);
  const history = useHistory();
  const setWalletProvider = useCallback(
    (web3authProvider) => {
      const walletProvider = getWalletProvider(chain, web3authProvider, uiConsole);
      setProvider(walletProvider);
    },
    [chain]
  );

  const currentChainConfig = CHAIN_CONFIG[chain];

  const subscribeAuthEvents = (web3auth) => {
    // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS

    web3auth.on(ADAPTER_EVENTS.CONNECTED, (data) => {
      // setWalletProvider(web3auth.provider);
      console.log("connected---------");
    });

    web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
      console.log("connecting---------");
    });

    web3auth.on(ADAPTER_EVENTS.READY, () => {
      console.log("ready---------");
    });

    web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
      console.log("disconnected--------");
    });

    web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
      console.error("some error or user has cancelled login request", error);
    });
  };

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true);
        const mnemonic = localStorage.getItem("mnemonic");
        let pkey = (web3Auth && web3Auth.walletAdapters.openlogin.openloginInstance.privKey) || "";

        if (pkey === "" && !mnemonic) {
          if (tempInfo) {
            pkey = tempInfo.pkey;
          } else {
            const seed = localStorage.getItem("seed");
            const rootKey = hdkey.fromMasterSeed(seed);
            const xpub = rootKey.publicExtendedKey();
            pkey = seed;
            if (rootKey) {
              const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/xpub`, { xpub: xpub });
              setUser(data.checkXpub);
            } else {
              return () => null;
            }
          }
        }
        const seed = mnemonic ? crypto.pbkdf2Sync(unorm.nfkd(mnemonic), "mnemonic" + "", 2048, 64, "sha512") : pkey;
        const rootKey = hdkey.fromMasterSeed(seed);
        const derived = rootKey.derivePath(
          generatePath(defaultAsset[defaultFocused.assetIndex].code, defaultFocused.addressIndex)
        );

        if (history.location.pathname === "/auth/external" && !isSafari) {
          const xpub = rootKey.publicExtendedKey();
          const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/xpub`, { xpub: xpub });
          if (data.status) {
            // window.opener.postMessage(data.checkXpub, "http://onepickdao.io/");
            window.opener.postMessage(data.checkXpub, "*");
            try {
              if (isSafari) {
                localStorage.clear();
                sessionStorage.clear();
              }
              await web3Auth.logout();
            } catch (e) {}
          }

          window.focus();
          window.close();
          return () => null;
        }

        const { address } = generateAddress(derived, defaultFocused.based);
        const nonce = await _provider[defaultFocused.based].eth.getTransactionCount(address, "pending");

        setRootKey(rootKey);
        if (seed) {
          localStorage.setItem("seed", seed);
        }
        const getFocused = localStorage.getItem("focused");

        const myFocused = getFocused ? JSON.parse(getFocused) : { ...defaultFocused, nonce, walletAddress: address };
        if (!getFocused) {
          localStorage.setItem("focused", JSON.stringify({ ...defaultFocused, nonce, walletAddress: address }));
        }

        setFocused(myFocused);

        const getAsset = localStorage.getItem(myFocused.based);
        if (!getAsset) {
          localStorage.setItem(myFocused.based, JSON.stringify(defaultAsset[defaultFocused.assetIndex]));
        }
        setAssets(getAsset ? JSON.parse(getAsset) : defaultAsset[defaultFocused.assetIndex]);
        setSeed(seed);

        // if (mnemonic) {
        //   setUser({ currentLoginProvider: "mnemonic" });
        //   history.replace("/");
        // }
        const user = await web3Auth.getUserInfo();
        // if (password) {
        const body = {
          password: password,
          recommend: recommend,
          pushToken: null,
          xpub: rootKey.publicExtendedKey(),
          user: user,
          mainAddress: myFocused.walletAddress,
        };

        const { data } = await axios.post(process.env.REACT_APP_HOST + "/authentication", body);
        if (data.status) {
          if (data.code === "invalid_recommend") {
            return history.push({ pathname: "/auth/register/input", state: { type: "social" } });
          }

          if (data.code === "request_logout") {
            return await logout();
          }

          setRecommend(null);
          setPassword(null);
          setUser({ ...user, referral_code: data.referralCode });
          window.localStorage.setItem("wallet-uuid", data.uuid);
          history.replace("/");
        }
        // } else {
        //   setUser(user);
        //   history.replace("/");
        // }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, [chain, web3AuthNetwork, setWalletProvider, toggleLogin]);
  // }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const clientId = process.env.REACT_APP_OPENLOGIN_CLIENT_ID;
        const web3AuthInstance = new Web3Auth({
          chainConfig: currentChainConfig,
          // get your client id from https://dashboard.web3auth.io
          clientId,
          // storageKey: history.location.pathname === "/auth/external" ? "session" : "local",
          uiConfig: {
            // appLogo: ICON,
            // loginMethodsOrder: ["google", "kakao", "facebook", "apple", "github"],
          },
        });

        // const adapter = new OpenloginAdapter({
        //   chainConfig: currentChainConfig,

        //   adapterSettings: {
        //     clientId,
        //     whiteLabel: {
        //       dark: true,
        //       logoLight: ICON,
        //       logoDark: ICON,
        //     },

        //     uxMode: history.location.pathname === "/auth/external" ? (isSafari ? "popup" : "popup") : "popup",
        //   },
        // });
        // web3AuthInstance.configureAdapter(adapter);
        subscribeAuthEvents(web3AuthInstance);
        setWeb3Auth(web3AuthInstance);
        await web3AuthInstance.initModal();
      } catch (e) {
        console.log("에러에러");
        console.log(e);
      }
    })();
  }, []);

  const login = async ({ pw, rec, main = false }) => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return () => null;
    }
    // const localProvider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
    //   relogin: true,
    //   loginProvider: "google",
    // });
    let localProvider = null;
    try {
      localProvider = await web3Auth.connect();
    } catch (e) {
      console.log(e);
      return;
    }

    setWalletProvider(localProvider);
    // const user = await web3Auth.getUserInfo();
    const seed = await web3Auth.walletAdapters.openlogin.openloginInstance.privKey;
    const rootKey = hdkey.fromMasterSeed(seed);
    const xpub = rootKey.publicExtendedKey();
    const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/xpub`, {
      xpub: xpub,
      password: pw,
      recommend: rec,
    });

    if (main) {
      if (data.result) {
        setToggleLogin(!toggleLogin);
      } else {
        // localStorage.clear();
        // sessionStorage.clear();
        // await web3Auth.logout();
        // await web3Auth.loginModal.closeModal();

        return "invalid_xpub";
      }
    } else {
      if (!pw && !rec) {
        return "invalid_xpub";
      }

      setTempInfo({ pkey: seed });
      setPassword(pw);
      setRecommend(rec);
      setToggleLogin(!toggleLogin);
    }
  };

  const loginExternal = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return () => null;
    }
    // const localProvider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
    //   relogin: true,
    //   loginProvider: "google",
    // });

    const localProvider = await web3Auth.connect();
    setWalletProvider(localProvider);
    if (history.location.pathname === "/auth/external") {
      const seed = await web3Auth.walletAdapters.openlogin.openloginInstance.privKey;
      const rootKey = hdkey.fromMasterSeed(seed);
      const xpub = rootKey.publicExtendedKey();
      const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/xpub`, {
        xpub: xpub,
        external: true,
      });
      if (data.status) {
        // window.opener.postMessage(data.checkXpub, "http://onepickdao.io/");
        window.opener.postMessage(data.checkXpub, "*");
        try {
          if (isSafari) {
            localStorage.clear();
            sessionStorage.clear();
          }
          await logout();
        } catch (e) {}
      }

      window.focus();
      window.close();
      return () => null;
    }

    // const user = await web3Auth.getUserInfo();
    // const seed = await web3Auth.walletAdapters.openlogin.openloginInstance.privKey;
    // const rootKey = hdkey.fromMasterSeed(seed);
  };

  const logout = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return () => null;
    }
    localStorage.removeItem("seed");
    await web3Auth.logout({ cleanup: true });
    setProvider(null);
  };

  const getUserInfo = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return () => null;
    }
    const user = await web3Auth.getUserInfo();
    return user;
  };

  const getAccounts = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return () => null;
    }
    await provider.getAccounts();
  };

  const getBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return () => null;
    }
    await provider.getBalance();
  };

  const signMessage = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return () => null;
    }
    await provider.signMessage();
  };

  const signTransaction = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return () => null;
    }
    await provider.signTransaction();
  };

  const signAndSendTransaction = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return () => null;
    }
    await provider.signAndSendTransaction();
  };

  const uiConsole = (...args) => {
    // const el = document.querySelector("#console>p");
    // if (el) {
    //   el.innerHTML = JSON.stringify(args || {}, null, 2);
    // }
  };

  const contextProvider = {
    web3Auth,
    chain,
    provider,
    user,
    setUser,
    isLoading,
    setIsLoading,
    login,
    loginExternal,
    logout,
    getUserInfo,
    getAccounts,
    getBalance,
    signMessage,
    signTransaction,
    signAndSendTransaction,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
