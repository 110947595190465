import React, { forwardRef } from "react";

import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import AdminHeader from "./AdminHeader";
import AdminDrawer from "./AdminDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";

const Page = forwardRef(({ children, title = "", header = false, ...rest }, ref) => {
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const toolbarHeight = smView ? 56 : 64;

  return (
    <div ref={ref} {...rest} style={{ width: "100vw", height: `calc(100vh - ${toolbarHeight}px)` }}>
      <Helmet>
        <title>ADMIN</title>
      </Helmet>
      {header && <AdminHeader />}
      <AdminDrawer
        adminTables={[
          { table: "user", title: "유저 목록" },
          { table: "error", title: "에러 목록" },
        ]}
      />
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
