import React from "react";
import { Box, Button, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { useWeb3Auth } from "services/web3auth";

import Page from "components/Common/Page";
import Logo from "resources/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(7),
  },
}));

const LoginView = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { login } = useWeb3Auth();

  const onClick = async ({ path, type }) => {
    const param = await login({ rec: null, pw: null, main: true });
    if (param === "invalid_xpub") {
      return history.push({ pathname: path, state: { type } });
    }
  };

  return (
    <Page className={classes.root} title="Login" bottomTab={false} pb={1}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
        <Container
          maxWidth="xs"
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 20,
          }}
        >
          <img alt="logo" src={Logo} style={{ width: "80%" }} />
          <Box width="100%">
            <Box width="100%" my={1}>
              <Button
                onClick={onClick.bind(null, { path: "/auth/register/input", type: "social" })}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t("소셜 로그인")}
              </Button>
            </Box>
            {/* <Box width="100%" my={1}>
              <Button
                onClick={onClick.bind(null, { path: "/auth/register/input", type: "generate" })}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t("새 지갑 만들기")}
              </Button>
            </Box>
            <Box width="100%">
              <Button
                onClick={onClick.bind(null, { path: "/auth/register/input", type: "load" })}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t("지갑 불러오기")}
              </Button>
            </Box> */}
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
