import React from "react";

import {
  Box,
  SwipeableDrawer,
  Button,
  Typography,
  TextField,
  Divider,
  Container,
  IconButton,
  capitalize,
  TableCell,
  TableRow,
  Table,
  TableContainer,
  TableBody,
} from "@mui/material";
import Dialog from "components/Common/Dialog";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import GlobalContext from "context/GlobalContext";
import useDialog from "hooks/useDialog";
import { getBalance, generateAddress, generatePath, runContract } from "utils";
import { sendEthereum as sendTransaction } from "transaction/ethereum";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import erc721Abi from "resources/abi/erc721Abi";
import axios from "axios";

const Drawer = (props) => {
  const { assets, focused, setFocused, rootKey, provider } = React.useContext(GlobalContext);
  const theme = useTheme();
  const { isOpen, setIsOpen } = props;
  const [loading, setLoading] = React.useState(false);
  const [isSecure, setIsSecure] = React.useState(true);
  const [password, setPassword] = React.useState("");
  const [message, setMessage] = React.useState(null);
  const [page, setPage] = React.useState("first");
  const [hash, setHash] = React.useState("");

  const [openDialog, dialogProps] = useDialog();
  const [dialogMessage, setDialogMessage] = React.useState("");

  const classes = useStyles();
  const { t } = useTranslation();
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return () => null;
    }
    setIsOpen(open);

    if (page === "third" || page === "fourth") {
      setPage("first");
      props.reset();
    }
  };

  const nextOnPress = () => {
    if (page === "first") {
      setPage("second");
    } else {
      setPage("first");
    }
  };

  const isEnough = async () => {
    if (props.isPoint) {
      const uuid = localStorage.getItem("wallet-uuid");
      const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/nonce`, { uuid });

      if (data.status) {
        if (focused.addressIndex < 0) {
          return false;
        }
        const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
        const { privateKey } = generateAddress(derived, focused.based);
        const signature = await provider[focused.based].eth.accounts.sign(
          provider[focused.based].utils.fromUtf8(`${data.nonce}`),
          privateKey
        );
        try {
          const { data: _data } = await axios.post(`${process.env.REACT_APP_HOST}/user/point`, {
            uuid,
            publicAddress: focused.walletAddress,
            signature,
          });

          return Number(_data.result) >= Number(props.amount);
        } catch (e) {
          console.log(e);
          return false;
        }
      }
    }

    if (props.nft) {
      const { result: ownerOf } = await runContract({
        abi: erc721Abi,
        contractAddress: props.contractAddress,
        type: "call",
        from: focused.walletAddress,
        web3: provider[focused.based],
        method: "ownerOf",
        input: [props.tokenId],
      });

      return ownerOf.toLowerCase() === focused.walletAddress.toLowerCase();
    } else {
      const balance = await getBalance({
        based: focused.based,
        walletAddress: focused.walletAddress,
        contractAddress: props.contractAddress,
        provider,
      });

      const total = props.name ? Number(props.amount) + Number(props.estimated.print) : Number(props.estimated.print);
      return balance >= total;
    }
  };

  const passwordAuthentication = async () => {
    setLoading(true);
    const isPossible = await isEnough();

    if (!isPossible) {
      if (props.isPoint) {
        setLoading(false);
        setDialogMessage(t("잔액이 부족합니다."));
        return openDialog();
      }
      if (props.nft) {
        setLoading(false);
        setDialogMessage(t("해당 NFT의 소유권이 없습니다."), { variant: "error" });
        return openDialog();
      } else {
        setLoading(false);
        setDialogMessage(t("잔액이 부족합니다."));
        return openDialog();
      }
    }
    const uuid = localStorage.getItem("wallet-uuid");
    const body = {
      uuid,
      password,
    };
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/authentication/re", body);
    if (data.status) {
      send();
    } else {
      setLoading(false);
      setDialogMessage(t("잘못된 비밀번호입니다."));
      return openDialog();
    }
  };

  const send = async () => {
    setLoading(true);

    if (props.isPoint) {
      const uuid = localStorage.getItem("wallet-uuid");
      const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/nonce`, { uuid });

      if (data.status) {
        if (focused.addressIndex < 0) {
          return false;
        }
        const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
        const { privateKey } = generateAddress(derived, focused.based);
        const signature = await provider[focused.based].eth.accounts.sign(
          provider[focused.based].utils.fromUtf8(`${data.nonce}`),
          privateKey
        );
        try {
          const { data: _data } = await axios.post(`${process.env.REACT_APP_HOST}/user/send`, {
            uuid,
            publicAddress: focused.walletAddress,
            signature,
            receive: props.receive,
            amount: props.amount,
          });

          if (_data.status) {
            setMessage(t("포인트 전송이 완료되었습니다."));
            setHash("0x0");
            setPage("third");
            setPassword("");
          } else {
            setMessage(result.message);
            setPage("fourth");
            setPassword("");
          }
        } catch (e) {
          console.log(e);
          return false;
        } finally {
          setLoading(false);
        }
      }
      return () => null;
    }

    const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
    const { privateKey } = generateAddress(derived, focused.based);

    const result = await sendTransaction({
      focused,
      setFocused,
      name: focused.based,
      web3: provider[focused.based],
      from: focused.walletAddress,
      based: "ethereum-" + props.checked,
      pkey: privateKey,
      deployInfo: props.deployInfo,
      to: props.receive,
      ...props.estimated,
    });

    if (result.status) {
      setMessage(
        t("트랜잭션 푸시가 완료되었습니다. 실제 잔액 반영은 트랜잭션 컨펌까지 약간의 시간이 걸릴 수 있습니다.")
      );
      setHash(result.message);
      setPage("third");
      setPassword("");
    } else {
      setMessage(result.message);
      setPage("fourth");
      setPassword("");
    }

    setLoading(false);
  };

  return (
    <React.Fragment>
      <Dialog {...dialogProps} title={t("알림")}>
        {dialogMessage}
      </Dialog>
      <SwipeableDrawer
        PaperProps={{ sx: { backgroundColor: "inherit", boxShadow: 0 } }}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        open={isOpen}
        // open={true}
        anchor="bottom"
      >
        {props.estimated && props.estimated.print && (
          <Container
            maxWidth="xs"
            sx={{ backgroundColor: "white", borderTopLeftRadius: 10, borderTopRightRadius: 10, minHeight: 500 }}
          >
            {page === "first" && (
              <Box sx={{ flex: 1, justifyContent: "space-between" }}>
                <React.Fragment>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {[
                          {
                            left: t("트랜잭션 유형"),
                            right: props.name
                              ? capitalize(props.name) + " (" + props.ticker.toUpperCase() + ")"
                              : "Contract Call",
                          },
                          { left: t("보내는 주소"), right: focused.walletAddress },
                          { left: props.isPoint ? t("받는 유저 이메일") : t("받는 주소"), right: props.receive },
                          { left: t("수수료"), right: props.estimated.print + " " + assets.ticker.toUpperCase() },
                          {
                            left: props.nft ? t("토큰 ID") : t("출금 금액"),
                            right: props.nft
                              ? props.tokenId
                              : props.name
                              ? props.amount + " " + props.ticker.toUpperCase()
                              : "-",
                          },
                        ].map((item, index) => {
                          return (
                            <TableRow key={index + "sendTable"}>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{item.left}</TableCell>
                              <TableCell sx={{ wordBreak: "break-all" }} className={classes.rightLabel} align="right">
                                {item.right}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className={classes.totalWrapper}>
                    <Box sx={{ pt: 2.5, alignItems: "flex-end", textAlign: "right" }}>
                      {props.contractAddress ? (
                        <React.Fragment>
                          {!props.nft && (
                            <Typography className={classes.totalLabel}>
                              {Number(props.amount).toFixed(8)} {props.ticker.toUpperCase()}
                            </Typography>
                          )}
                          <Typography className={classes.totalLabel}>
                            {Number(props.estimated.print).toFixed(8)} {assets.ticker.toUpperCase()}
                          </Typography>
                        </React.Fragment>
                      ) : (
                        props.name && (
                          <Typography className={classes.totalLabel}>
                            {(Number(props.amount) + Number(props.estimated.print)).toFixed(8)}{" "}
                            {props.ticker.toUpperCase()}
                          </Typography>
                        )
                      )}

                      {props.name && (
                        <Typography align="right" sx={{ mt: 1 }}>
                          {props.nft ? t("수수료") : t("출금 금액") + " + " + t("수수료")}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </React.Fragment>
                <Box sx={{ ...classes.totalWrapper, display: "flex", justifyContent: "flex-end", mt: 2.5 }}>
                  <Button onClick={nextOnPress}>{t("다음")}</Button>
                </Box>
              </Box>
            )}
            {page === "second" && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                sx={{
                  minHeight: 500,
                  py: 2.5,
                }}
              >
                <TextField
                  fullWidth
                  type="password"
                  size="medium"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("비밀번호")}
                />
                {/* {supportBio && (
                    <Box className={classes.bioWrapper}>
                      <IconButton onClick={bioAuthentication} className={classes.bioTouchable}>
                        <Ionicons name="finger-print-outline" size={18} color={theme.palette.link} />
                        <Typography className={classes.link}>{t("생체 인증을 사용합니다.")}</Typography>
                      </IconButton>
                    </Box>
                  )} */}
                <Button fullWidth variant="contained" size="large" disabled={loading} onClick={passwordAuthentication}>
                  {loading ? t("전송중...") : t("승인")}
                </Button>
              </Box>
            )}
            {page === "third" && (
              <Box sx={{ px: 2.5, py: 5, display: "flex", flexDirection: "column", minHeight: 500 }}>
                <Box className={classes.okWrapper}>
                  <CheckCircleOutlineIcon sx={{ fontSize: 120, color: "green" }} />
                  <Typography className={classes.okLabel}>{message}</Typography>
                </Box>
                <Box className={classes.totalWrapper}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    {props.contractAddress ? (
                      <React.Fragment>
                        {!props.nft && (
                          <Typography className={classes.totalLabel}>
                            {Number(props.amount).toFixed(8)} {props.ticker.toUpperCase()}
                          </Typography>
                        )}
                        <Typography className={classes.totalLabel}>
                          {Number(props.estimated.print).toFixed(8)} {assets.ticker.toUpperCase()}
                        </Typography>
                      </React.Fragment>
                    ) : (
                      props.name && (
                        <Typography className={classes.totalLabel}>
                          {(Number(props.amount) + Number(props.estimated.print)).toFixed(8)}{" "}
                          {props.ticker.toUpperCase()}
                        </Typography>
                      )
                    )}
                    {props.name && (
                      <Typography className={classes.totalCaption}>
                        {props.nft ? t("수수료") : t("출금 금액") + " + " + t("수수료")}
                      </Typography>
                    )}
                    <Button onClick={toggleDrawer(false)}>{t("확인")}</Button>
                  </Box>
                </Box>
              </Box>
            )}
            {page === "fourth" && (
              <Box sx={{ px: 2.5, py: 5, display: "flex", flexDirection: "column", minHeight: 500 }}>
                <Box className={classes.okWrapper}>
                  <ErrorOutlineIcon sx={{ fontSize: 120, color: "red" }} />
                  <Typography className={classes.errorLabel}>{message}</Typography>
                </Box>
                <Box className={classes.totalWrapper}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    {props.contractAddress ? (
                      <React.Fragment>
                        {!props.nft && (
                          <Typography className={classes.totalLabel}>
                            {Number(props.amount).toFixed(8)} {props.ticker.toUpperCase()}
                          </Typography>
                        )}
                        <Typography className={classes.totalLabel}>
                          {Number(props.estimated.print).toFixed(8)} {assets.ticker.toUpperCase()}
                        </Typography>
                      </React.Fragment>
                    ) : (
                      props.name && (
                        <Typography className={classes.totalLabel}>
                          {(Number(props.amount) + Number(props.estimated.print)).toFixed(8)}{" "}
                          {props.ticker.toUpperCase()}
                        </Typography>
                      )
                    )}
                    {props.name && (
                      <Typography className={classes.totalCaption}>
                        {props.nft ? t("수수료") : t("출금 금액") + " + " + t("수수료")}
                      </Typography>
                    )}
                    <Button onClick={toggleDrawer(false)}>{t("확인")}</Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Container>
        )}
      </SwipeableDrawer>
      {props.children}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  okLabel: {
    textAlign: "center",
    marginTop: 10,
    fontSize: 16,
    fontWeight: "bold",
    color: "green",
    lineHeight: 1.5,
  },
  errorLabel: {
    textAlign: "center",
    marginTop: 10,
    fontSize: 16,
    fontWeight: "bold",
    color: "red",
    lineHeight: 1.5,
  },
  okWrapper: {
    paddingHorizontal: 20,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary,
  },
  bioWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  bioTouchable: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
  },
  link: {
    color: theme.palette.link,
    textDecorationLine: "underline",
  },
  totalWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  rightLabel: {
    fontSize: 12,
    fontFamily: "monospace",
  },
  totalLabel: {
    fontWeight: "bold",
    fontSize: 20,
    fontFamily: "monospace",
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
}));

export default Drawer;
