import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomDialog = ({ open, handleClose, title, children, ok = null }) => {
  const { t } = useTranslation();
  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("닫기")}
        </Button>
        {ok && (
          <Button onClick={ok} color="primary">
            {t("확인")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
