import { Transaction, FeeMarketEIP1559Transaction } from "@ethereumjs/tx";
import Common, { Chain, CustomChain } from "@ethereumjs/common";

const filter = (props) => {
  if (props.based === "ethereum-legacy") {
    return Transaction.fromTxData(props.transaction, props.option);
  } else {
    return FeeMarketEIP1559Transaction.fromTxData({ ...props.transaction, type: 0x02 }, props.option);
  }
};

const signTx = async (props) => {
  const chainId = await props.web3.eth.net.getId();
  const transaction = {
    nonce: props.nonce,
    chainId,
    ...props.fields,
  };
  let option = null;
  switch (props.name) {
    case "ethereum": {
      option = {
        common: new Common({
          chain: Chain.Mainnet,
          hardfork: "london",
        }),
      };
      break;
    }
    case "ethereum-ropsten": {
      option = {
        common: new Common({
          chain: Chain.Ropsten,
          hardfork: "london",
        }),
      };
      break;
    }
    case "ethereum-goerli": {
      option = {
        common: new Common({
          chain: Chain.Goerli,
          hardfork: "london",
        }),
      };
      break;
    }
    case "polygon": {
      option = {
        common: Common.custom(CustomChain.PolygonMainnet),
      };
      break;
    }
    case "polygon-mumbai": {
      option = {
        common: Common.custom(CustomChain.PolygonMumbai),
      };
      break;
    }
    case "binance_smart_chain": {
      option = {
        common: Common.custom({
          name: "Binance Smart Chain",
          networkId: chainId,
          chainId,
          defaultHardfork: "london",
        }),
      };
      break;
    }
    case "binance_smart_chain-testnet": {
      option = {
        common: Common.custom({
          name: "Binance Smart Chain Testnet",
          networkId: chainId,
          chainId,
          defaultHardfork: "london",
        }),
      };
      break;
    }
    case "plumchain": {
      option = {
        common: Common.custom({
          name: "plumchain",
          networkId: chainId,
          chainId,
          defaultHardfork: "london",
        }),
      };
      break;
    }
    case "starnet": {
      option = {
        common: Common.custom({
          name: "starnet",
          networkId: chainId,
          chainId,
          defaultHardfork: "london",
        }),
      };
      break;
    }
    case "klaytn": {
      option = {
        common: Common.custom({
          name: "klaytn",
          networkId: chainId,
          chainId,
          defaultHardfork: "london",
        }),
      };
      break;
    }
    case "klaytn-baobab": {
      option = {
        common: Common.custom({
          name: "klaytn",
          networkId: chainId,
          chainId,
          defaultHardfork: "london",
        }),
      };
      break;
    }
  }

  const tx = filter({ ...props, transaction, option });
  const signedTx = tx.sign(Buffer.from(props.pkey, "hex"));
  const serializedTx = signedTx.serialize();
  return "0x" + serializedTx.toString("hex");
};

const sendTx = async (props) => {
  const signedTx = await signTx(props);
  const hash = await new Promise(async (resolve) => {
    await props.web3.eth
      .sendSignedTransaction(signedTx)
      .once("transactionHash", (hash) => {
        resolve(hash);
      })
      .catch(async (e) => {
        switch (e.message) {
          default: {
            resolve("resend");
          }
        }
      });
  });

  return hash;
};

export const sendEthereum = async (props) => {
  try {
    let fields = null;
    let message = null;
    let data = props.deployInfo.deploy;
    let value = props.deployInfo.value;
    let to = props.to;

    const focused = props.focused;
    let nonce = null;

    if (!focused.nonce) {
      nonce = await props.web3.eth.getTransactionCount(props.from, "pending");
    }

    if (props.based === "ethereum-legacy") {
      // gasLimit: props.gasLimit,
      fields = {
        data,
        nonce,
        from: props.from,
        gasLimit: props.gas,
        gasPrice: props.gasPrice,
        to,
        value,
      };
    } else {
      fields = {
        data,
        nonce,
        maxPriorityFeePerGas: props.maxPriorityFeePerGas,
        maxFeePerGas: props.maxFeePerGas,
        from: props.from,
        gasLimit: props.gas,
        to,
        value,
      };
    }

    message = await sendTx({ ...props, fields });
    if (message === "resend") {
      nonce = await props.web3.eth.getTransactionCount(props.from, "pending");
      message = await sendTx({ ...props, fields: { ...fields, nonce } });
    }

    props.setFocused({ ...props.focused, nonce: nonce + 1 });
    return { status: true, message };
  } catch (e) {
    console.log(e);
    props.setFocused({ ...props.focused, nonce: null });
    return { status: false, message: e.message };
  }
};
