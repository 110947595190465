import crypto from "crypto-browserify";
import axios from "axios";

import web3 from "web3";
import bs58 from "bs58";
import { ec as EC } from "elliptic";

import PolygonscanIconDisable from "resources/image/polygonscan_icon_disable.png";
import PolygonscanIconEnable from "resources/image/polygonscan_icon_enable.png";
import EtherscanIconDisable from "resources/image/etherscan_icon_disable.png";
import EtherscanIconEnable from "resources/image/etherscan_icon_enable.png";
import PlumscanIconDisable from "resources/image/plumscan_icon_disable.png";
import PlumscanIconEnable from "resources/image/plumscan_icon_enable.png";
import BscscanIconDisable from "resources/image/bscscan_icon_disable.png";
import BscscanIconEnable from "resources/image/bscscan_icon_enable.png";

import IconOpt from "resources/image/icon_opt.png";
import IconStar from "resources/image/icon_star.png";
import IconEth from "resources/image/icon_eth.png";
import IconBtc from "resources/image/icon_btc.png";
import IconTrx from "resources/image/icon_trx.png";
import IconFil from "resources/image/icon_fil.png";
import IconPlc from "resources/image/icon_plc.png";
import IconEthRopsten from "resources/image/icon_eth_ropsten.png";
import IconMatic from "resources/image/icon_matic.png";
import IconMaticMumbai from "resources/image/icon_matic_mumbai.png";
import IconBnb from "resources/image/icon_bnb.png";
import IconBnbTestnet from "resources/image/icon_bnb_testnet.png";
import IconKlay from "resources/image/icon_klay.png";
import IconKlayBaobab from "resources/image/icon_klay_baobab.png";

// import { sendEthereum } from "src/transaction/ethereum";

export const chainName = (name) => {
  return name.replace(/_/g, " ");
};

export const getPrice = async (based) => {
  switch (based) {
    case "ethereum":
    case "ethereum-ropsten":
    case "ethereum-goerli": {
      const { data } = await axios.get(`https://www.binance.com/api/v3/ticker/price?symbol=ETHUSDT`);
      return data.price;
    }
    case "polygon":
    case "polygon-mumbai": {
      const { data } = await axios.get(`https://www.binance.com/api/v3/ticker/price?symbol=MATICUSDT`);
      return data.price;
    }
    case "binance_smart_chain":
    case "binance_smart_chain-testnet": {
      const { data } = await axios.get(`https://www.binance.com/api/v3/ticker/price?symbol=BNBUSDT`);
      return data.price;
    }
    case "klaytn":
    case "klaytn-baobab": {
      const { data } = await axios.get(`https://www.binance.com/api/v3/ticker/price?symbol=KLAYUSDT`);
      return data.price;
    }
    case "plumchain": {
      return 0;
    }
    default: {
      return 0;
    }
  }
};

export const runContract = async (props) => {
  const { method, input, abi, contractAddress, type, from, web3 } = props;

  if (!contractAddress) {
    return {
      result: { limit: 21000, deploy: null, value: web3.utils.toHex(input[1].toString()) },
    };
  }

  if (!abi.some((item) => item.name === method)) {
    return () => null;
  }

  const contract = new web3.eth.Contract(abi, contractAddress);
  switch (type) {
    case "call": {
      const call = await contract.methods[method](...input).call({ from });
      return { result: call };
    }
    case "deploy": {
      const limit = await contract.methods[method](...input).estimateGas({ from });
      const deploy = await contract.methods[method](...input).encodeABI();

      return {
        result: {
          deploy,
          limit,
          value: null,
        },
      };
    }
    default: {
      throw Error();
    }
  }
};

export const explorerFactory = (name) => {
  switch (name) {
    case "starnet": {
      return {
        baseUri: "https://starnetscan.com/",
        address: "address",
      };
    }
    case "ethereum": {
      return {
        baseUri: "https://etherscan.io/",
        address: "address",
      };
    }
    case "ethereum-ropsten": {
      return {
        baseUri: "https://ropsten.etherscan.io/",
        address: "address",
      };
    }
    case "ethereum-goerli": {
      return {
        baseUri: "https://goerli.etherscan.io/",
        address: "address",
      };
    }
    case "bitcoin": {
      return {
        baseUri: "",
        address: "address",
      };
    }
    case "tron": {
      return {
        baseUri: "",
        address: "address",
      };
    }
    case "filecoin": {
      return {
        baseUri: "",
        address: "address",
      };
    }
    case "plumchain": {
      return {
        baseUri: "https://plumscan.xyz/",
        address: "address",
      };
    }
    case "polygon": {
      return {
        baseUri: "https://polygonscan.com/",
        address: "address",
      };
    }
    case "polygon-mumbai": {
      return {
        baseUri: "https://mumbai.polygonscan.com/",
        address: "address",
      };
    }
    case "klaytn": {
      return {
        baseUri: "https://scope.klaytn.com/",
        address: "account",
      };
    }
    case "klaytn-baobab": {
      return {
        baseUri: "https://baobab.scope.klaytn.com/",
        address: "account",
      };
    }
    case "binance_smart_chain": {
      return {
        baseUri: "https://bscscan.com/",
        address: "address",
      };
    }
    case "binance_smart_chain-testnet": {
      return {
        baseUri: "https://testnet.bscscan.com/",
        address: "address",
      };
    }
  }
};

export const symbolFactory = (name, type = "coin") => {
  switch (type) {
    case "coin": {
      switch (name) {
        case "starnet": {
          return IconStar;
        }
        case "onepick": {
          return IconOpt;
        }
        case "ethereum": {
          return IconEth;
        }
        case "bitcoin": {
          return IconBtc;
        }
        case "tron": {
          return IconTrx;
        }
        case "filecoin": {
          return IconFil;
        }
        case "plumchain": {
          return IconPlc;
        }
        case "ethereum-goerli":
        case "ethereum-ropsten": {
          return IconEthRopsten;
        }
        case "klaytn": {
          return IconKlay;
        }
        case "klaytn-baobab": {
          return IconKlayBaobab;
        }
        case "polygon": {
          return IconMatic;
        }
        case "polygon-mumbai": {
          return IconMaticMumbai;
        }
        case "binance_smart_chain": {
          return IconBnb;
        }
        case "binance_smart_chain-testnet": {
          return IconBnbTestnet;
        }

        default: {
          return null;
        }
      }
    }

    case "explorer-enable": {
      switch (name) {
        case "ethereum":
        case "ethereum-ropsten": {
          return EtherscanIconEnable;
        }
        case "polygon":
        case "polygon-mumbai": {
          return PolygonscanIconEnable;
        }
        case "binance_smart_chain":
        case "binance_smart_chain-testnet": {
          return BscscanIconEnable;
        }
        case "plumchain": {
          return PlumscanIconEnable;
        }
        default: {
          return null;
        }
      }
    }
    case "explorer-disable": {
      switch (name) {
        case "ethereum":
        case "ethereum-ropsten": {
          return EtherscanIconDisable;
        }
        case "polygon":
        case "polygon-mumbai": {
          return PolygonscanIconDisable;
        }
        case "binance_smart_chain":
        case "binance_smart_chain-testnet": {
          return BscscanIconDisable;
        }
        case "plumchain": {
          return PlumscanIconDisable;
        }
        default: {
          return null;
        }
      }
    }
  }
};

export const generatePath = (code, index) => {
  if (index === undefined || index === undefined) {
    throw Error("invalid code or index :-) ");
  }
  return `m/44'/${code}'/0'/0/${index}`;
};

// export const sendTransaction = async (props) => {
//   switch (props.based) {
//     case "ethereum-eip1559": {
//       return await sendEthereum({ ...props, saveStore, getStore });
//     }
//     case "ethereum-legacy": {
//       return await sendEthereum({ ...props, saveStore, getStore });
//     }
//     case "ethereum-token-legacy": {
//       return "";
//     }
//     case "ethereum-token-eip1559": {
//       return "";
//     }
//   }
// };

export const generateAddress = (derived, based) => {
  switch (based) {
    case "starnet":
    case "ethereum":
    case "ethereum-ropsten":
    case "ethereum-goerli":
    case "polygon":
    case "polygon-mumbai":
    case "klaytn":
    case "klaytn-baobab":
    case "binance_smart_chain":
    case "binance_smart_chain-testnet": {
      // const xpub = derived.publicExtendedKey();
      // const publicWallet = hdkey.fromExtendedKey(xpub).getWallet();
      const wallet = derived.getWallet();
      const privateKey = wallet.getPrivateKey().toString("hex");
      const address = "0x" + wallet.getAddress().toString("hex");
      return {
        name: "ETH",
        privateKey,
        address,
      };
    }
    case "plumchain": {
      // const xpub = derived.publicExtendedKey();
      // const publicWallet = hdkey.fromExtendedKey(xpub).getWallet();
      const wallet = derived.getWallet();
      const privateKey = wallet.getPrivateKey().toString("hex");
      const address = "0x" + wallet.getAddress().toString("hex");
      return {
        name: "PLC",
        privateKey,
        address,
      };
    }
    case "bitcoin": {
      // const xprv = derived.privateExtendedKey();
      // const base58pubkey = bip32.fromBase58(xprv).publicKey;
      // const privateKey = bip32.fromBase58(xprv).privateKey.toString("hex");
      // const { address: SegWit_P2SH_P2WPKH } = bjs.payments.p2sh({
      //   redeem: bjs.payments.p2wpkh({
      //     pubkey: base58pubkey,
      //   }),
      // });
      // const { address: P2PKH } = bjs.payments.p2pkh({ pubkey: base58pubkey });
      // const { address: P2WPKH } = bjs.payments.p2wpkh({ pubkey: base58pubkey });
      // return {
      //   name: "BTC",
      //   privateKey,
      //   address: SegWit_P2SH_P2WPKH, // default > segwit based gogo
      //   another: {
      //     SegWit_P2SH_P2WPKH,
      //     P2PKH,
      //     P2WPKH,
      //   },
      // };
    }
    case "tron": {
      const wallet = derived.getWallet();
      const privateKey = Buffer.from(wallet.getPrivateKey(), "hex");

      const ecdh = new EC("secp256k1");
      const node = ecdh.keyFromPrivate(privateKey);
      const pubkey = node.getPublic("hex");

      const dropped = pubkey.toString("hex").substring(2, pubkey.lengrh);

      const keccak256 = web3.utils.keccak256(Buffer.from(dropped, "hex"));
      const replaced = "41" + keccak256.substring(keccak256.length - 40, keccak256.length);
      const firstHash = crypto.createHash("sha256").update(Buffer.from(replaced, "hex")).digest("hex");
      const secondHash = crypto.createHash("sha256").update(Buffer.from(firstHash, "hex")).digest("hex");

      const checksum = secondHash.substring(0, 8);

      const digest = replaced + checksum;
      const address = bs58.encode(Buffer.from(digest, "hex"));
      return {
        name: "TRX",
        privateKey: privateKey.toString("hex"),
        address,
      };
    }
    default: {
      return {
        name: null,
        privateKey: null,
        address: null,
      };
    }
  }
};

export const capitalize = (text) => text.substring(0, 1).toUpperCase() + text.substring(1, text.length);

export const getBalance = async (props) => {
  switch (props.based) {
    case "starnet":
    case "ethereum":
    case "ethereum-ropsten":
    case "ethereum-goerli":
    case "polygon":
    case "polygon-mumbai":
    case "binance_smart_chain":
    case "binance_smart_chain-testnet":
    case "klaytn":
    case "klaytn-baobab":
    case "plumchain": {
      if (props.contractAddress) {
        const contract = new props.provider[props.based].eth.Contract(
          [
            {
              constant: true,
              inputs: [
                {
                  internalType: "address",
                  name: "owner",
                  type: "address",
                },
              ],
              name: "balanceOf",
              outputs: [
                {
                  internalType: "uint256",
                  name: "",
                  type: "uint256",
                },
              ],
              payable: false,
              stateMutability: "view",
              type: "function",
            },
          ],
          props.contractAddress
        );
        const balanceWei = await contract.methods.balanceOf(props.walletAddress).call();
        if (props.nft) {
          return balanceWei;
        } else {
          const balance = props.provider[props.based].utils.fromWei(balanceWei);
          return balance;
        }
      } else {
        const balanceWei = await props.provider[props.based].eth.getBalance(props.walletAddress);
        const balance = props.provider[props.based].utils.fromWei(balanceWei);
        return balance;
      }
    }
    case "bitcoin": {
    }
    case "tron": {
    }
    case "filecoin": {
    }
    default: {
    }
  }
};

export const customFromWei = (balance, decimal) => balance / Math.pow(10, decimal);
