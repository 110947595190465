import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  capitalize,
} from "@mui/material";
import useDialog from "hooks/useDialog";
import Dialog from "components/Common/Dialog";

import LockResetIcon from "@mui/icons-material/LockReset";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CodeIcon from "@mui/icons-material/Code";
import KeyIcon from "@mui/icons-material/Key";
import PasswordIcon from "@mui/icons-material/Password";
import RestoreIcon from "@mui/icons-material/Restore";
import TranslateIcon from "@mui/icons-material/Translate";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import PushPinIcon from "@mui/icons-material/PushPinOutlined";
import { useEffectOnce } from "react-use";
// import * as bip39 from "bip39";
// import { hdkey } from "ethereumjs-wallet";
// import { useTranslation } from "react-i18next";

import Page from "components/Common/Page";
import AssetCard from "components/Home/AssetCard";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import { useWeb3Auth } from "services/web3auth";
import { Global } from "@emotion/react";
import { useHistory } from "react-router-dom";
import AssetRow from "components/Home/AssetRow";
import NftRow from "components/Home/NftRow";
import { chainName, explorerFactory, generateAddress, generatePath } from "utils";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";

const LoginView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { focused, bottomNav, rootKey, assets, provider } = React.useContext(GlobalContext);
  const [openDialog, dialogProps] = useDialog();
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [pkey, setPkey] = React.useState(null);
  const [mnemonic, setMnemonic] = React.useState(null);

  const [ok, setOk] = React.useState(null);

  const [isRefresing, setIsRefresing] = React.useState(false); //refresh control
  const [refreshedAt, setRefreshedAt] = React.useState(new Date()); // refresh control

  const [isAdmin, setIsAdmin] = React.useState(null);
  const { logout, getUserInfo, setUser, user } = useWeb3Auth();

  const onRefresh = async () => {
    const now = new Date();
    const rollby = parseInt((now.getTime() - refreshedAt.getTime()) / 1000);

    if (rollby < 10) {
      setDialogMessage(`${10 - rollby}` + t("초 뒤에 다시 시도해주세요."));
      openDialog();
      return () => null;
    } else {
      setIsRefresing(true);
      setRefreshedAt(now);
      setDialogMessage(t("새로고침 되었습니다."));

      setTimeout(() => {
        setIsRefresing(false);
      }, 1000);
    }
  };

  const copyOnClick = () => {
    setOk(null);
    setDialogMessage(t("클립보드에 복사되었습니다."));
    openDialog();
    document.getElementById("copybtn").click();
  };

  useEffectOnce(() => {
    (async () => {
      const uuid = localStorage.getItem("wallet-uuid");
      const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/nonce`, { uuid });
      setUser({ ...user, referral_code: data.referral_code });
      if (data.status) {
        if (focused.addressIndex < 0) {
          return () => null;
        }
        const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
        const { privateKey } = generateAddress(derived, focused.based);
        const signature = await provider[focused.based].eth.accounts.sign(
          provider[focused.based].utils.fromUtf8(`${data.nonce}`),
          privateKey
        );
        try {
          const { data: _data } = await axios.post(`${process.env.REACT_APP_HOST}/amiadmin`, {
            uuid,
            publicAddress: focused.walletAddress,
            signature,
          });
          if (_data.status) {
            setIsAdmin(true);
          }
        } catch (e) {}
      }
    })();
  });

  return (
    <Page
      refresh={onRefresh}
      title="Wallet"
      header
      network
      subtitle={`${assets.mainnet ? `[Mainnet] ` : `[Testnet] `} ${capitalize(chainName(focused.based))}`}
    >
      <Dialog {...dialogProps} title={t("알림")} ok={ok}>
        {dialogMessage}
      </Dialog>
      {bottomNav === "wallet" && (
        <React.Fragment>
          <AssetCard copyOnClick={copyOnClick} />
          <Box py={1} display={"flex"} alignItems="center"></Box>
          <Box py={1} display={"flex"} justifyContent={"space-between"} alignItems="center">
            <Typography fontWeight={"bold"} variant="h5">
              Fungible Tokens
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              <Typography fontSize={14}>{assets && assets.list.length}/10</Typography>
              <PushPinIcon fontSize="sm" />
            </Box>
          </Box>
          <Box px={1}>
            {assets.list.map((item, index) => {
              return <AssetRow isRefresing={isRefresing} item={item} key={"asset." + index} />;
            })}
            {/* 포인트 항목 */}
            {/* <AssetRow
              isRefresing={isRefresing}
              item={{
                decimal: 18,
                name: "point",
                ticker: "point",
                isPoint: true,
              }}
            /> */}
            <ListItemButton onClick={() => history.push("/addasset")} sx={{ p: 0 }}>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent="center"
                py={1}
                px={2}
                sx={{ width: "100%", height: 60, boxShadow: "0px 0px 7.5px 1px rgba(0,0,0,0.3)" }}
              >
                <AddCircleOutlineIcon />
              </Box>
            </ListItemButton>
          </Box>
          <Box mt={2} py={1} display={"flex"} justifyContent={"space-between"} alignItems="center">
            <Typography fontWeight={"bold"} variant="h5">
              Non Fungible Tokens
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              <Typography fontSize={14}>{assets && assets.nfts.length}/10</Typography>
              <PushPinIcon fontSize="sm" />
            </Box>
          </Box>
          <Box px={1}>
            {assets.nfts.map((item, index) => {
              return <NftRow isRefresing={isRefresing} item={item} key={"nfts.." + index} />;
            })}
            <ListItemButton disabled sx={{ p: 0 }}>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent="center"
                py={1}
                px={2}
                sx={{ width: "100%", height: 60, boxShadow: "0px 0px 7.5px 1px rgba(0,0,0,0.3)" }}
              >
                <AddCircleOutlineIcon />
              </Box>
            </ListItemButton>
          </Box>
        </React.Fragment>
      )}
      {bottomNav === "more" && (
        <React.Fragment>
          <List>
            {[
              {
                copy: true,
                icon: <CodeIcon />,
                title: t("내 추천 코드"),
                console: user.referral_code,
              },
              {
                icon: <LockResetIcon />,
                title: t("비밀번호 변경"),
                onClick: () => history.push("/reset/password"),
              },
              {
                icon: <AccountBalanceWalletIcon />,
                title: t("지갑 주소 선택"),
                onClick: () => history.push("/hdwallet"),
              },
              {
                icon: <KeyIcon />,
                title: t("개인키 내보내기"),
                console: pkey,
                onClick: () => {
                  if (focused.addressIndex < 0) {
                    setDialogMessage(t("직접 추가한 지갑주소는 개인키를 내보낼 수 없습니다."));
                    return openDialog();
                  }
                  setOk(() => {
                    return () => {
                      const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
                      const { privateKey } = generateAddress(derived, focused.based);
                      setPkey(privateKey);
                      dialogProps.handleClose();
                    };
                  });
                  setDialogMessage(
                    t("현재 활성화중인 지갑 주소의 개인키를 내보냅니다. [주의 - 개인키는 절대 유출되어선 안됩니다.]")
                  );
                  openDialog();
                },
              },
              // {
              //   icon: <PasswordIcon />,
              //   title: t("니모닉 내보내기"),
              //   console: mnemonic,
              //   onClick: async () => {
              //     if (localStorage.getItem("mnemonic")) {
              //       setOk(() => {
              //         return () => {
              //           setMnemonic(localStorage.getItem("mnemonic"));
              //           dialogProps.handleClose();
              //         };
              //       });
              //       setDialogMessage(
              //         t("현재 활성화중인 니모닉을 내보냅니다. [주의 - 니모닉은 절대 유출되어선 안됩니다.]")
              //       );
              //       openDialog();
              //       return ()=>null;
              //     }

              //     try {
              //       const { email } = await getUserInfo();
              //       if (email) {
              //         setDialogMessage(t("소셜 로그인한 유저는 니모닉을 내보낼 수 없습니다."));
              //         openDialog();
              //         return ()=>null;
              //       } else {
              //         setOk(() => {
              //           return () => {
              //             setMnemonic(localStorage.getItem("mnemonic"));
              //             dialogProps.handleClose();
              //           };
              //         });
              //         setDialogMessage(
              //           t("현재 활성화중인 니모닉을 내보냅니다. [주의 - 니모닉은 절대 유출되어선 안됩니다.]")
              //         );
              //         openDialog();
              //         return ()=>null;
              //       }
              //     } catch (e) {
              //       if (e.message === "Wallet is not connectedNo wallet is connected") {
              //         setOk(() => {
              //           return () => {
              //             setMnemonic(localStorage.getItem("mnemonic"));
              //             dialogProps.handleClose();
              //           };
              //         });
              //         setDialogMessage(
              //           t("현재 활성화중인 니모닉을 내보냅니다. [주의 - 니모닉은 절대 유출되어선 안됩니다.]")
              //         );
              //         openDialog();
              //         return ()=>null;
              //       }
              //     }
              //   },
              // },
              // {
              //   icon: <AddCircleIcon />,
              //   title: t("지갑 주소 추가하기"),
              //   onClick: () => {
              //     history.push("/addaddress");
              //   },
              // },
              {
                icon: <RestoreIcon />,
                title: t("전송 기록"),
                onClick: () => {
                  const { baseUri, address } = explorerFactory(focused.based);
                  window.open(baseUri + address + "/" + focused.walletAddress);
                },
              },
              {
                icon: <RestoreIcon />,
                title: t("어드민 페이지"),
                red: true,
                onClick: () => {
                  history.push("/admin/user");
                },
              },
              // { icon: <TranslateIcon />, title: t("언어"), onClick: () => null },
              {
                icon: <LogoutIcon />,
                title: t("로그아웃"),
                onClick: () => {
                  setOk(() => {
                    return async () => {
                      try {
                        if (localStorage.getItem("mnemonic")) {
                          localStorage.removeItem("mnemonic");
                        } else {
                          await logout();
                        }

                        localStorage.removeItem("wallet-uuid");
                        localStorage.removeItem("seed");
                        localStorage.removeItem("focused");
                        localStorage.removeItem("polygon");
                        localStorage.removeItem("polygon-mumbai");
                        localStorage.removeItem("ethereum");
                        localStorage.removeItem("ethereum-ropsten");
                        localStorage.removeItem("ethereum-goerli");
                        localStorage.removeItem("plumchain");
                        localStorage.removeItem("binance_smart_chain-testnet");
                        localStorage.removeItem("binance_smart_chain");
                        localStorage.removeItem("klaytn");
                        localStorage.removeItem("klaytn-baobab");
                        setUser(null);
                        dialogProps.handleClose();
                      } catch (e) {
                        if (e.message === "Wallet is not connectedNo wallet is connected") {
                          localStorage.removeItem("wallet-uuid");
                          localStorage.removeItem("seed");
                          localStorage.removeItem("focused");
                          localStorage.removeItem("polygon");
                          localStorage.removeItem("polygon-mumbai");
                          localStorage.removeItem("ethereum");
                          localStorage.removeItem("ethereum-ropsten");
                          localStorage.removeItem("ethereum-goerli");
                          localStorage.removeItem("plumchain");
                          localStorage.removeItem("binance_smart_chain-testnet");
                          localStorage.removeItem("binance_smart_chain");
                          localStorage.removeItem("klaytn");
                          localStorage.removeItem("klaytn-baobab");
                          setUser(null);
                          dialogProps.handleClose();
                        }
                      }
                    };
                  });
                  setDialogMessage("정말 로그아웃 하시겠습니까? 등록된 지갑 정보가 삭제됩니다.");
                  openDialog();
                },
              },
            ].map((item, index) => {
              if (item.red && !isAdmin) {
                return () => null;
              }

              return (
                <React.Fragment key={index + "morelist"}>
                  <ListItem sx={{ px: 0, py: 0.5 }}>
                    {item.copy ? (
                      <CopyToClipboard
                        text={user.referral_code}
                        onCopy={() => {
                          enqueueSnackbar(t("클립보드로 복사되었습니다."), { variant: "info" });
                        }}
                      >
                        <ListItemButton sx={{ px: 0 }}>
                          <ListItemIcon sx={{ color: item.red && "red" }}>{item.icon}</ListItemIcon>
                          <ListItemText sx={{ color: item.red && "red" }} primary={item.title} />
                        </ListItemButton>
                      </CopyToClipboard>
                    ) : (
                      <ListItemButton onClick={item.onClick} sx={{ px: 0 }}>
                        <ListItemIcon sx={{ color: item.red && "red" }}>{item.icon}</ListItemIcon>
                        <ListItemText sx={{ color: item.red && "red" }} primary={item.title} />
                      </ListItemButton>
                    )}
                  </ListItem>
                  {item.console && (
                    <Box
                      sx={{
                        p: 1,
                        border: "1px solid #eaeaea",
                        borderRadius: 1,
                        wordWrap: "break-word",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "monospace",
                          userSelect: "text",
                        }}
                      >
                        {item.console}
                      </Typography>
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </React.Fragment>
      )}
      <Container maxWidth="xs"></Container>
    </Page>
  );
};

export default LoginView;
