export const defaultAsset = [
  {
    ticker: "eth",
    name: "ethereum",
    based: "ethereum",
    mainnet: true,
    status: true,
    code: 60,
    provider: "",
    fee: {
      legacy: true,
      eip1559: true,
    },
    list: [
      {
        name: "ethereum",
        ticker: "eth",
        icon: "ethereum",
        contractAddress: "",
        decimal: 18,
      },
      {
        name: "onepick",
        ticker: "opt",
        icon: "onepick",
        contractAddress: "0xd175d05122680bf69391edb9dce3eef980d786e5",
        decimal: 18,
      },
    ],
    nfts: [],
  },
  {
    ticker: "eth",
    name: "ethereum",
    based: "ethereum-goerli",
    mainnet: false,
    status: true,
    code: 60,
    provider: "",
    fee: {
      legacy: true,
      eip1559: true,
    },
    list: [
      {
        name: "ethereum",
        ticker: "eth",
        icon: "ethereum-goerli",
        contractAddress: "",
        decimal: 18,
      },
      {
        name: "onepick",
        ticker: "opt",
        icon: "onepick",
        contractAddress: "0x0d641dd92c6cbc1946ba11f8ebfb92cd1167660c",
        decimal: 18,
      },
    ],
    nfts: [],
  },
  {
    ticker: "matic",
    name: "polygon",
    based: "polygon",
    mainnet: true,
    status: true,
    code: 60,
    provider: "",
    fee: {
      legacy: true,
      eip1559: false,
    },
    list: [
      {
        name: "polygon",
        ticker: "matic",
        icon: "polygon",
        contractAddress: "",
        decimal: 18,
      },
      {
        name: "onepick",
        ticker: "opt",
        icon: "onepick",
        contractAddress: "0x0F5b7e301dBBCE7c18f4711a5f6Eb8fA063d7bcB",
        decimal: 18,
      },
    ],
    nfts: [
      {
        name: "onepick",
        ticker: "opt",
        icon: "onepick",
        contractAddress: "0x3f3b55195f7b689e5b1edeea58558b126595b67f",
      },
      {
        name: "One Pick Citizen NFT",
        ticker: "opct",
        icon: "onepick",
        contractAddress: "0x77d46ab0d2b84d4DADC1508ee787f76163CB9774",
      },
    ],
  },
  {
    ticker: "matic",
    name: "polygon",
    based: "polygon-mumbai",
    mainnet: false,
    status: true,
    code: 60,
    provider: "",
    fee: {
      legacy: true,
      eip1559: false,
    },
    list: [
      {
        name: "polygon",
        ticker: "matic",
        icon: "polygon-mumbai",
        contractAddress: "",
        decimal: 18,
      },
    ],
    nfts: [],
  },
];

export const defaultFocused = {
  walletAddress: "",
  addressIndex: 0,
  assetIndex: 0, // Asset Array Index
  based: "ethereum",
  nonce: null,
};
