import React from "react";
import { Typography, Box, ListItemButton, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import EmptyIcon from "resources/image/icon_empty.png";
import { symbolFactory } from "utils";
import GlobalContext from "context/GlobalContext";
import axios from "axios";
import { getBalance, generateAddress, generatePath } from "utils";
import numeral from "numeral";

const AssetRow = (props) => {
  const item = props.item;
  const history = useHistory();
  const { provider, focused, rootKey, assets } = React.useContext(GlobalContext);
  const [balance, setBalance] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      if (props.item.isPoint) {
        setTimeout(async () => {
          const uuid = localStorage.getItem("wallet-uuid");
          const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/nonce`, { uuid });
          if (data.status) {
            if (focused.addressIndex < 0) {
              return () => null;
            }
            const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
            const { privateKey } = generateAddress(derived, focused.based);
            const signature = await provider[focused.based].eth.accounts.sign(
              provider[focused.based].utils.fromUtf8(`${data.nonce}`),
              privateKey
            );
            try {
              const { data } = await axios.post(`${process.env.REACT_APP_HOST}/user/point`, {
                uuid,
                publicAddress: focused.walletAddress,
                signature,
              });
              setBalance(data.result);
            } catch (e) {}
          }
        }, 350);
      } else {
        setBalance(
          await getBalance({
            contractAddress: item.contractAddress,
            based: focused.based,
            provider,
            walletAddress: focused.walletAddress,
          })
        );
      }
    })();
  }, [props.isRefresing]);

  return (
    <ListItemButton
      onClick={
        balance === null
          ? () => null
          : () =>
              history.push({
                pathname: props.item.isPoint ? "/transfer/point" : "/transfer",
                state: {
                  contractAddress: item.contractAddress,
                  ...item,
                },
              })
      }
      sx={{ p: 0, mb: 1.5 }}
    >
      {balance === null ? (
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          py={1}
          px={2}
          sx={{ width: "100%", height: 60, boxShadow: "0px 0px 7.5px 1px rgba(0,0,0,0.3)" }}
        >
          <CircularProgress size={15} />
        </Box>
      ) : (
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="space-between"
          py={1}
          px={2}
          sx={{ width: "100%", height: 60, boxShadow: "0px 0px 7.5px 1px rgba(0,0,0,0.3)" }}
        >
          <Box display={"flex"} alignItems="center">
            <img style={{ width: 26 }} src={item.icon ? symbolFactory(item.icon) : EmptyIcon} />
            <Typography fontWeight={"bold"} lineHeight={1} fontSize={17} ml={1}>
              {item.ticker.toUpperCase()}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems="center">
            <Typography lineHeight={1} fontSize={17} ml={1}>
              {numeral(balance).format("0,0.[00000000]")} {item.ticker.toUpperCase()}
            </Typography>
          </Box>
        </Box>
      )}
    </ListItemButton>
  );
};

export default AssetRow;
