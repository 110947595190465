import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import SlideRoutes from "react-slide-routes";
import { Container, CircularProgress } from "@mui/material";

import Home from "pages/Home";
import Networks from "pages/Networks";
import Transfer from "pages/Transfer";
import TransferPoint from "pages/TransferPoint";
import TransferNft from "pages/TransferNft";
import AddAsset from "pages/AddAsset";
import AddAddress from "pages/AddAddress";
import AddNft from "pages/AddNft";
import QrAddress from "pages/QrAddress";
import SelectAddress from "pages/SelectAddress";
import ResetPassword from "pages/ResetPassword";
import Admin from "pages/Admin";
import AuthMain from "pages/Auth/Main";
import ExternalLogin from "pages/Auth/ExternalLogin";
import InputAuth from "pages/Auth/InputAuth";
import LoadMnemonic from "pages/Auth/LoadMnemonic";
import GenerateMnemonic from "pages/Auth/GenerateMnemonic";
import { useWeb3Auth } from "services/web3auth";

import "styles/css/transition.css";

const LoginRoutes = ({ location }) => {
  const slideList = [
    "/",
    "/networks",
    "/transfer",
    "/transfer/point",
    "/transfernft",
    "/addasset",
    "/addnft",
    "/hdwallet",
    "/qraddress",
    "/addaddress",
    "/reset/password",
  ];
  return (
    <SlideRoutes location={location} pathList={slideList}>
      <Route exact path="/" component={Home} />
      <Route path="/qraddress" component={QrAddress} />
      <Route path="/networks" component={Networks} />
      <Route path="/transfer/point" component={TransferPoint} />
      <Route path="/transfer" component={Transfer} />
      <Route path="/transfernft" component={TransferNft} />
      <Route path="/addasset" component={AddAsset} />
      <Route path="/addaddress" component={AddAddress} />
      <Route path="/addnft" component={AddNft} />
      <Route path="/hdwallet" component={SelectAddress} />
      <Route path="/reset/password" component={ResetPassword} />
      <Switch>
        <Route path="/admin" component={Admin} />
      </Switch>
      <Redirect from="*" to="/" />
    </SlideRoutes>
  );
};

const LogoutRoutes = ({ location }) => {
  return (
    <SlideRoutes
      location={location}
      pathList={["/", "/auth/register/input", "/auth/register/mnemonic", "/auth/load/mnemonic"]}
    >
      <Route exact path="/" component={AuthMain} />
      <Route exact path="/auth/external" component={ExternalLogin} />
      <Route path="/auth/register/input" component={InputAuth} />
      <Route path="/auth/load/mnemonic" component={LoadMnemonic} />
      <Route path="/auth/register/mnemonic" component={GenerateMnemonic} />
      <Redirect from="*" to="/" />
    </SlideRoutes>
  );
};

const AppRouter = () => {
  const { isLoading, user } = useWeb3Auth();
  const location = useLocation();
  return isLoading ? (
    <Container sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Container>
  ) : user ? (
    <LoginRoutes location={location} />
  ) : (
    <LogoutRoutes location={location} />
  );
};

export default AppRouter;
