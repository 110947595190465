import React, { useContext, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CheckIcon from "@mui/icons-material/Check";
import {
  Pagination,
  Typography,
  Box,
  CircularProgress,
  Divider,
  Button,
  capitalize,
  ImageList,
  ImageListItem,
  useMediaQuery,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Dialog from "components/Common/Dialog";
import useDialog from "hooks/useDialog";
import { useSnackbar } from "notistack";

import Page from "components/Common/Page";
import { useTranslation } from "react-i18next";

import { useWeb3Auth } from "services/web3auth";
import { TextField } from "@mui/material";
import useInput from "hooks/useInput";
import { getBalance, runContract, chainName } from "utils";
import GlobalContext from "context/GlobalContext";
import erc20Abi from "resources/abi/erc20Abi";
import erc721Abi from "resources/abi/erc721Abi";
import { useLocation } from "react-router-dom";
import NetworkConfig from "components/Estimate/NetworkConfig";
import DrawerProvider from "components/Common/DrawerProvider";
import axios from "axios";

const PER_PAGE = 9;
const NFT_IMG_SIZE_MEDIUM = 120;
const NFT_IMG_SIZE_SMALL = 90;

const LoginView = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { focused, provider, assets } = React.useContext(GlobalContext);
  const { t } = useTranslation();
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const NFT_IMG_SIZE = smView ? NFT_IMG_SIZE_SMALL : NFT_IMG_SIZE_MEDIUM;
  const [isValid, setIsValid] = React.useState(false);
  const [validSpinner, setValidSpinner] = React.useState(false);
  const [deployInfo, setDeployInfo] = React.useState(null);
  const [disabled, setDisabled] = React.useState(true);
  const [estimated, setEstimated] = React.useState(null);
  const [checked, setChecked] = React.useState("legacy");
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [nftList, setNftList] = React.useState(null);
  const [listboxHeight, setListboxHeight] = React.useState(380);

  const [balance, setBalance] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const receiveInput = useInput("");

  const onValidPress = async () => {
    if (!provider[focused.based].utils.isAddress(receiveInput.value)) {
      return enqueueSnackbar(t("올바른 지갑주소를 입력해주세요."), { variant: "error" });
    }

    setValidSpinner(true);
    const { result: ownerOf } = await runContract({
      abi: erc721Abi,
      contractAddress: location.state.contractAddress,
      type: "call",
      from: focused.walletAddress,
      web3: provider[focused.based],
      method: "ownerOf",
      input: [selected],
    });

    if (ownerOf.toLowerCase() !== focused.walletAddress.toLowerCase()) {
      return enqueueSnackbar(t("해당 NFT의 소유권이 없습니다."), { variant: "error" });
    }

    const { result: transferFrom } = await runContract({
      abi: erc721Abi,
      contractAddress: location.state.contractAddress,
      type: "deploy",
      from: focused.walletAddress,
      web3: provider[focused.based],
      method: "transferFrom",
      input: [focused.walletAddress, receiveInput.value, selected],
    });

    setDeployInfo(transferFrom);
    setValidSpinner(false);
    setIsValid(!isValid);
  };

  const reset = () => {
    receiveInput.setValue("");
    setIsValid(false);
  };

  const onNextPress = () => {
    if (!provider[focused.based].utils.isAddress(receiveInput.value)) {
      return enqueueSnackbar(t("올바른 지갑주소를 입력해주세요."), { variant: "error" });
    }

    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    (async () => {
      const balance = await getBalance({
        contractAddress: location.state.contractAddress,
        based: focused.based,
        provider,
        walletAddress: focused.walletAddress,
        nft: true,
      });
      setBalance(balance);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (balance) {
        const { result: tokenURI } = await runContract({
          abi: erc721Abi,
          contractAddress: location.state.contractAddress,
          type: "call",
          from: focused.walletAddress,
          web3: provider[focused.based],
          method: "tokenURI",
          input: [2],
        });

        setLoading(true);
        const list = [];
        const start = balance - page * PER_PAGE;
        const end = balance - (page - 1) * PER_PAGE;
        for (let i = start; i < end; i++) {
          if (i >= balance || i < 0) {
            continue;
          } else {
            const { result: tokenOfOwnerByIndex } = await runContract({
              abi: erc721Abi,
              contractAddress: location.state.contractAddress,
              type: "call",
              from: focused.walletAddress,
              web3: provider[focused.based],
              method: "tokenOfOwnerByIndex",
              input: [focused.walletAddress, i],
            });
            const { result: tokenURI } = await runContract({
              abi: erc721Abi,
              contractAddress: location.state.contractAddress,
              type: "call",
              from: focused.walletAddress,
              web3: provider[focused.based],
              method: "tokenURI",
              input: [tokenOfOwnerByIndex],
            });

            try {
              if (false) {
                const { data } = await axios.get(tokenURI);
                const isImageIPFS = data.image ? data.image.split("ipfs://") : [];
                if (1 < isImageIPFS.length) {
                  data.imageOriginal = data.image;
                  data.image = "https://ipfs.io/ipfs/" + isImageIPFS[1];
                }

                list.push({ ...data, tokenId: tokenOfOwnerByIndex });
              } else {
                const { data } = await axios.get(tokenURI);
                console.log(data);
                list.push({ ...data, tokenId: tokenOfOwnerByIndex });
              }
            } catch (e) {
              list.push({ tokenId: tokenOfOwnerByIndex });
            }
          }
        }
        if (list.length < 4) {
          setListboxHeight(127);
        } else if (list.length < 7) {
          setListboxHeight(254);
        } else {
          setListboxHeight(380);
        }

        setNftList(list);
        setLoading(false);
      }
    })();
  }, [balance, page]);

  return (
    <Page
      goBack
      title="Transfer"
      invisibleRight
      header
      subtitle={`${assets.mainnet ? `[Mainnet] ` : `[Testnet] `} ${capitalize(chainName(focused.based))}`}
    >
      <DrawerProvider
        deployInfo={deployInfo}
        reset={reset}
        checked={checked}
        receive={location.state.contractAddress ? location.state.contractAddress : receiveInput.value}
        estimated={estimated}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        nft
        tokenId={selected}
        {...location.state}
      >
        <Box my={2}>
          {nftList && (
            <Typography variant="h5" textAlign={"center"}>
              {nftList.length > 0 ? t("전송할 NFT를 선택해주세요.") : t("전송할 NFT가 없습니다.")}
            </Typography>
          )}
        </Box>
        {balance && (
          <Box display="flex" justifyContent="center" py={2}>
            <Pagination
              onChange={(_, value) => {
                setPage(value);
              }}
              count={parseInt(balance / PER_PAGE + 1)}
            />
          </Box>
        )}
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: listboxHeight,
            position: "relative",
          }}
        >
          {loading && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 2,
                backgroundColor: "rgba(255,255,255,0.6)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={25} color="primary" />
            </Box>
          )}
          <ImageList style={{}} cols={3} rowHeight={NFT_IMG_SIZE} gap={10}>
            {nftList &&
              nftList.length > 0 &&
              !loading &&
              nftList.map((item, index) => {
                const isVideo = item.image && item.image.includes("mp4");

                return (
                  <ImageListItem style={{ overflow: "hidden", width: NFT_IMG_SIZE }} key={"nft" + index}>
                    <Box
                      onClick={() => {
                        setSelected(item.tokenId);
                      }}
                      sx={{
                        backgroundColor: item.tokenId === selected ? "rgba(255,255,255,0.6)" : "none",
                        ":hover": {
                          backgroundColor: "rgba(255,255,255,0.4)",
                        },
                        cursor: "pointer",
                        zIndex: 1,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pb: 1,
                      }}
                    >
                      {item.tokenId === selected && <CheckIcon fontSize="large" />}
                    </Box>
                    {isVideo ? (
                      <video>
                        <source src={item.image} type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        style={{ width: NFT_IMG_SIZE }}
                        loading="lazy"
                        src={`${item.image}?w=${NFT_IMG_SIZE}&h=${NFT_IMG_SIZE}&fit=crop&auto=format`}
                        // srcSet={`${item.image}?w=${NFT_IMG_SIZE}&h=${NFT_IMG_SIZE}&fit=crop&auto=format&dpr=2 2x`}
                      />
                    )}
                  </ImageListItem>
                );
              })}
          </ImageList>
        </Box>
        {/* <Box sx={{ maxHeight: 410, overflow: "auto" }} display={"flex"} justifyContent="space-between" flexWrap="wrap">
          {nftList &&
            nftList.length > 0 &&
            nftList.map((item, index) => {
              const isVideo = item.image.includes("mp4");

              return (
                <Box position={"relative"} width="32.5%" key={"nft" + index}>
                  <Box
                    onClick={() => {
                      setSelected(item.tokenId);
                    }}
                    sx={{
                      backgroundColor: item.tokenId === selected ? "rgba(255,255,255,0.6)" : "none",
                      ":hover": {
                        backgroundColor: "rgba(255,255,255,0.4)",
                      },
                      cursor: "pointer",
                      zIndex: 1,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      pb: 1,
                    }}
                  >
                    {item.tokenId === selected && <CheckIcon fontSize="large" />}
                  </Box>

                  {isVideo ? (
                    <video width={"100%"}>
                      <source src={item.image} type="video/mp4" />
                    </video>
                  ) : (
                    <Box
                      style={{ backgroundImage: `url(${item.image})`, backgroundSize: "cover" }}
                      width="100%"
                      height="auto"
                    />
                  )}
                </Box>
              );
            })}
        </Box> */}
        {selected && (
          <Box style={{ position: "relative" }}>
            {focused.addressIndex < 0 && (
              <Box
                style={{
                  position: "absolute",
                  zIndex: 999,
                  backgroundColor: "rgba(255,255,255,0.6)",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                {t("직접 추가한 지갑주소로는 송금할 수 없습니다.")}
              </Box>
            )}
            <TextField
              sx={{ mt: 2, mb: 1 }}
              placeholder={t("받는 사람 지갑 주소 (0x...)")}
              fullWidth
              size="small"
              value={receiveInput.value}
              onChange={receiveInput.onChange}
            />

            <Divider sx={{ my: 2 }} />
            {!isValid && (
              <Box mt={1} display={"flex"} justifyContent="flex-end">
                <Button onClick={onValidPress}>
                  {validSpinner ? (
                    <CircularProgress size={15} />
                  ) : (
                    <Box display={"flex"} alignItems={"center"} lineHeight={1}>
                      {t("검증")}
                      <ChevronRightIcon fontSize="small" color={"primary"} />
                    </Box>
                  )}
                </Button>
              </Box>
            )}
          </Box>
        )}

        {isValid && (
          <React.Fragment>
            <NetworkConfig
              deployInfo={deployInfo}
              checked={checked}
              setChecked={setChecked}
              estimated={estimated}
              setEstimated={setEstimated}
              isOpen={isOpen}
              disabled={disabled}
              setDisabled={setDisabled}
            />
            <Divider />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button onClick={reset}>
                <Typography sx={{ lineHeight: 1, fontSize: 15 }}>{t("초기화")}</Typography>
              </Button>
              <Button onClick={onNextPress}>
                <Typography sx={{ lineHeight: 1, fontSize: 15 }}>{t("다음")}</Typography>
                <ChevronRightIcon />
              </Button>
            </Box>
          </React.Fragment>
        )}
      </DrawerProvider>
    </Page>
  );
};

export default LoginView;
