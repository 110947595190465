import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";

import AssetAdd from "components/Common/AssetAdd";
import Dialog from "components/Common/Dialog";
import useDialog from "hooks/useDialog";

import Page from "components/Common/Page";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

import { useWeb3Auth } from "services/web3auth";
import GlobalContext from "context/GlobalContext";
import { capitalize, TextField, InputAdornment, IconButton, Typography } from "@mui/material";
import { chainName } from "utils";
import useInput from "hooks/useInput";
import { useSnackbar } from "notistack";
import erc20Abi from "resources/abi/erc20Abi";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(9),
  },
}));

const AddAssetView = () => {
  const { t } = useTranslation();
  const { assets, setAssets, focused, provider } = React.useContext(GlobalContext);
  const [openDialog, dialogProps] = useDialog();
  const [dialogMessage, setDialogMessage] = useState("");
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const addressInput = useInput("");
  const history = useHistory();
  const { logout } = useWeb3Auth();
  const { enqueueSnackbar } = useSnackbar();
  const [searchInfo, setSearchInfo] = React.useState(null);

  const addOnPress = async () => {
    if (10 <= assets.nfts.length) {
      return enqueueSnackbar(t("최대 10개까지만 등록 가능합니다."), { variant: "error" });
    }

    const isDuplicate = assets.nfts.some((item) => {
      return item.contractAddress === addressInput.value;
    });

    if (isDuplicate) {
      return enqueueSnackbar(t("이미 등록된 항목입니다."), { variant: "error" });
    }

    try {
      const based = assets;
      based.nfts.push({
        contractAddress: searchInfo.address,
        name: searchInfo.name,
        ticker: searchInfo.ticker,
      });
      localStorage.setItem(focused.based, JSON.stringify(based));
      setAssets(based);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t("올바른 계약 주소가 아닙니다."), { variant: "error" });
    } finally {
      setLoading(!loading);
      history.goBack();
    }
  };

  React.useEffect(() => {
    (async () => {
      if (addressInput.value.length >= 42) {
        if (addressInput.value.length !== 42) {
          return enqueueSnackbar(t("올바른 계약 주소가 아닙니다."), { variant: "error" });
        }
        try {
          const contract = new provider[focused.based].eth.Contract(erc20Abi, addressInput.value);
          setLoading(true);
          const ticker = await contract.methods.symbol().call();
          const name = await contract.methods.name().call();
          setSearchInfo({
            ticker,
            name,
            address: addressInput.value,
          });
        } catch (e) {
          enqueueSnackbar(t("올바른 계약 주소가 아닙니다."), { variant: "error" });
        } finally {
          setLoading(false);
        }
      } else {
        setSearchInfo(null);
      }
    })();
  }, [addressInput.value]);

  return (
    <Page
      goBack
      title="Add Nft"
      header
      subtitle={`${assets.mainnet ? `[Mainnet] ` : `[Testnet] `} ${capitalize(chainName(focused.based))}`}
    >
      <TextField
        fullWidth
        placeholder={t("토큰 계약 주소를 입력해주세요.") + " (0x...)"}
        size="small"
        value={addressInput.value}
        onChange={addressInput.onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton sx={{ p: 0 }} onClick={() => null} onMouseDown={() => null}>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    color:
                      addressInput.value.length === 42 ? "green" : addressInput.value.length > 42 ? "red" : "black",
                  }}
                >
                  {addressInput.value.length}/42
                </Typography>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {((loading && !searchInfo) || searchInfo) && (
        <AssetAdd nft onPress={addOnPress} {...searchInfo} loading={loading} />
      )}
    </Page>
  );
};

export default AddAssetView;
