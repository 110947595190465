import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";

import Dialog from "components/Common/Dialog";
import useDialog from "hooks/useDialog";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
// import * as bip39 from "bip39";
import { hdkey } from "ethereumjs-wallet";
// import { useTranslation } from "react-i18next";

import Page from "components/Common/Page";
import AssetCard from "components/Home/AssetCard";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import { useWeb3Auth } from "services/web3auth";
import { defaultAsset, defaultFocused } from "utils/constants";
import { symbolFactory, capitalize, generateAddress, generatePath } from "utils";
import { chainName } from "utils";

const LoginView = () => {
  const { t } = useTranslation();
  const { setAssets, assets, focused, setFocused, seed, provider, setBackdrop } = React.useContext(GlobalContext);
  const [expanded, setExpanded] = React.useState(assets.mainnet ? "mainnet" : "testnet");

  const handleToggle = async (item) => {
    setBackdrop(true);
    const rootKey = hdkey.fromMasterSeed(seed);
    const derived = rootKey.derivePath(generatePath(item.code, 0));
    const { address } = generateAddress(derived, item.based);
    const nonce = await provider[item.based].eth.getTransactionCount(address, "pending");
    const newFocused = { ...defaultFocused, nonce, based: item.based, walletAddress: address };
    setBackdrop(false);
    localStorage.setItem(item.based, localStorage.getItem(item.based) ?? JSON.stringify(item));
    localStorage.setItem("focused", JSON.stringify(newFocused));
    setAssets(localStorage.getItem(item.based) ? JSON.parse(localStorage.getItem(item.based)) : item);
    setFocused(newFocused);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const NetworkListItem = (item, index) => {
    return (
      <ListItem
        key={item.based}
        disabled={!item.status}
        secondaryAction={
          <Checkbox
            edge="start"
            checked={focused.based === item.based}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": item.based }}
            disabled={!item.status}
          />
        }
        disablePadding
      >
        <ListItemButton disabled={!item.status} role={undefined} onClick={handleToggle.bind(null, item)}>
          <ListItemIcon>
            <img style={{ width: 24, height: 24 }} src={symbolFactory(item.based)} />
          </ListItemIcon>
          <ListItemText id={item.based} primary={capitalize(chainName(item.based))} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Page
      goBack
      invisibleRight
      title="Change Network"
      header
      subtitle={`${assets.mainnet ? `[Mainnet] ` : `[Testnet] `} ${capitalize(chainName(focused.based))}`}
    >
      <Accordion expanded={expanded === "mainnet"} onChange={handleChange("mainnet")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{t("메인 네트워크")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {defaultAsset.filter(({ mainnet }) => mainnet).map(NetworkListItem)}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "testnet"} onChange={handleChange("testnet")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>{t("테스트 네트워크")}</Typography>
        </AccordionSummary>
        <AccordionDetails>{defaultAsset.filter(({ mainnet }) => !mainnet).map(NetworkListItem)}</AccordionDetails>
      </Accordion>
    </Page>
  );
};

export default LoginView;
