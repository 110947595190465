import { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  Box,
  Chip,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Backdrop,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as bip39 from "bip39";
import { hdkey } from "ethereumjs-wallet";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useWeb3Auth } from "services/web3auth";
import { useHistory } from "react-router-dom";

import { defaultAsset, defaultFocused } from "utils/constants";
import { generatePath, generateAddress } from "utils";
import Page from "components/Common/Page";
import Dialog from "components/Common/Dialog";
import useDialog from "hooks/useDialog";
import GlobalContext from "context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(7),
  },
  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  chipWrapper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    width: 120,
    height: 120,
    borderRadius: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const LoginView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [openDialog, dialogProps] = useDialog();
  const { setRootKey, provider, setFocused, setSeed, setAssets } = useContext(GlobalContext);
  const [dialogMessage, setDialogMessage] = useState("");
  const [myMnemonic, setMyMnemonic] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUser, setIsLoading } = useWeb3Auth();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!isChecked) {
      setDialogMessage(t("니모닉 확인 체크를 해야합니다."));
      openDialog();
      return () => null;
    }
    setLoading(true);
    setIsLoading(true);

    const seed = bip39.mnemonicToSeedSync(myMnemonic);
    const rootKey = hdkey.fromMasterSeed(seed);

    const password = history.location.state.password;

    const derived = rootKey.derivePath(
      generatePath(defaultAsset[defaultFocused.assetIndex].code, defaultFocused.addressIndex)
    );
    const { address } = generateAddress(derived, defaultFocused.based);
    const nonce = await provider[defaultFocused.based].eth.getTransactionCount(address, "pending");

    setRootKey(rootKey);
    const getFocused = localStorage.getItem("focused");

    const myFocused = getFocused ? JSON.parse(getFocused) : { ...defaultFocused, nonce, walletAddress: address };
    if (!getFocused) {
      localStorage.setItem("focused", JSON.stringify({ ...defaultFocused, nonce, walletAddress: address }));
    }
    setFocused(myFocused);

    const getAsset = localStorage.getItem(myFocused.based);
    if (!getAsset) {
      localStorage.setItem(myFocused.based, JSON.stringify(defaultAsset[defaultFocused.assetIndex]));
    }
    setAssets(getAsset ? JSON.parse(getAsset) : defaultAsset[defaultFocused.assetIndex]);

    setSeed(seed);
    setUser({ currentLoginProvider: "mnemonic" });
    const body = {
      password,
      pushToken: null,
      xpub: rootKey.publicExtendedKey(),
    };

    const { data } = await axios.post(process.env.REACT_APP_HOST + "/authentication", body);

    setTimeout(() => {
      if (data.status) {
        setIsLoading(false);
        window.localStorage.setItem("wallet-uuid", data.uuid);
        window.localStorage.setItem("mnemonic", myMnemonic);
        history.replace("/");
      } else {
        setDialogMessage(JSON.stringify(data));
      }
    }, 500);
  };

  return (
    <Page bottomTab={false} className={classes.root} title="" header goBack pb={1}>
      <Backdrop className={classes.backdrop} open={loading}>
        <Box className={classes.loadingBox}>
          <CircularProgress size={30} />
          <Box mt={1}>
            <Typography color="textPrimary" variant="subtitle2">
              {t("생성 중...")}
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Dialog {...dialogProps} title={t("지갑 생성 - 비밀번호 만들기")}>
        {dialogMessage}
      </Dialog>
      <form className={classes.form} onSubmit={onSubmit}>
        <Container maxWidth="xs">
          <Box mb={1}>
            <Typography color="textPrimary" variant="h2">
              {t("지갑 불러오기 - 니모닉 입력")}
            </Typography>
          </Box>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {t("지갑을 생성하기 위한 니모닉을 생성합니다.")}
          </Typography>
          <Typography color="primary" gutterBottom variant="caption">
            {/* {t(
              "* 니모닉이란? 지갑주소의 비밀번호 역할을 하는 비밀키는 복잡하고 긴 난수로 되어있습니다. 이를 간단하고 기억하기 쉬운 12개의 단어로 보여줌으로써 유저 편의를 증대시키는 지갑 생성 방식입니다."
            )} */}
          </Typography>
          <Box className={classes.chipWrapper}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={8}
              onChange={({ target: { value } }) => setMyMnemonic(value)}
              placeholder={t("니모닉을 입력해주세요. 각 니모닉은 공백 한칸(' ') 으로 구분합니다.")}
              style={{ width: "100%", fontSize: 17, padding: 5 }}
            />

            {/* {myMnemonic &&
              myMnemonic.split(" ").map((item, index) => {
                let icon;
                return (
                  <li key={item + index}>
                    <Chip className={classes.chip} icon={icon} label={`${index + 1}. ${item}`} />
                  </li>
                );
              })} */}
          </Box>
        </Container>
        <Container maxWidth="xs">
          <Typography variant="h6">
            {t("* 12개의 단어를 반드시 따로 메모해주세요. 단어를 분실시 지갑을 복구할 수 없습니다.")}
          </Typography>
          <FormControlLabel
            control={<Checkbox size="small" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />}
            label={<Typography variant="body2">{t("니모닉을 저장했음을 확인합니다.")}</Typography>}
          />
          <Button color={isChecked ? "primary" : "secondary"} fullWidth size="large" type="submit" variant="contained">
            {t("다음")}
          </Button>
        </Container>
      </form>
    </Page>
  );
};

export default LoginView;
