import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import Web3 from "web3";

const web3Factory = {
  ethereum: createAlchemyWeb3(`https://eth-mainnet.g.alchemy.com/v2/iiAAAlI_piGSFOWqjbT9vWlBXOWx8XvR`),
  "ethereum-ropsten": new Web3(
    new Web3.providers.HttpProvider(`https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`)
  ),
  "ethereum-goerli": new Web3(
    new Web3.providers.HttpProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`)
  ),
  polygon: new Web3(
    new Web3.providers.HttpProvider(`https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`)
  ),
  "polygon-mumbai": new Web3(
    new Web3.providers.HttpProvider(`https://polygon-mumbai.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`)
  ),
  binance_smart_chain: createAlchemyWeb3("https://bsc-dataseed.binance.org"),
  "binance_smart_chain-testnet": createAlchemyWeb3("https://data-seed-prebsc-1-s1.binance.org:8545"),
  klaytn: new Web3(new Web3.providers.HttpProvider("https://public-node-api.klaytnapi.com/v1/cypress")),
  "klaytn-baobab": new Web3(new Web3.providers.HttpProvider("https://api.baobab.klaytn.net:8651/")),
  plumchain: new Web3(new Web3.providers.HttpProvider("https://node1.plc.plumchain.xyz/https")),
};
export default web3Factory;
