import React, { useContext, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";

import Page from "components/Common/Page";
import { useTranslation } from "react-i18next";

import { useWeb3Auth } from "services/web3auth";
import GlobalContext from "context/GlobalContext";
import { capitalize, Button, CircularProgress, Container, TextField, Divider } from "@mui/material";
import { chainName } from "utils";
import { useSnackbar } from "notistack";
import { generatePath, generateAddress, getBalance } from "utils";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import useInput from "hooks/useInput";
import { useHistory } from "react-router-dom";

const useclassNames = makeStyles((theme) => {
  return {
    monospace: {
      fontFamily: "monospace",
      fontSize: 13,
    },
    tableLoader: {
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      backgroundColor: "rgba(255,255,255,0.6)",
    },
    appbar: {
      width: "100%",
      backgroundColor: theme.palette.background.default,
      shadowOpacity: 0,
      elevation: 0,
    },
    container: {
      flex: 1,
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    captionWrapper: {
      width: "100%",
      padding: 10,
      textAlign: "left",
    },
    // caption: sheets.caption,
    // headerTitle: sheets.headerTitle,
    // headerSubTitle: sheets.headerSubTitle,
  };
});

const AddAssetView = () => {
  const classes = useclassNames();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const { t } = useTranslation();
  const { provider, focused, assets, rootKey } = React.useContext(GlobalContext);
  const [loading, setLoading] = React.useState(false);

  const password = useInput("");
  const newPassword = useInput("");
  const repeatNewPassword = useInput("");

  const passwordChange = async () => {
    if (!password.value || !newPassword.value || !repeatNewPassword.value) {
      return enqueueSnackbar(t("비밀번호를 입력해주세요."), { variant: "error" });
    }
    if (password.value.length < 6 || newPassword.value.length < 6 || repeatNewPassword.value.length < 6) {
      return enqueueSnackbar(t("비밀번호가 짧습니다."), { variant: "error" });
    }

    if (newPassword.value !== repeatNewPassword.value) {
      return enqueueSnackbar(t("두 비밀번호가 다릅니다."), { variant: "error" });
    }

    const uuid = localStorage.getItem("wallet-uuid");
    const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/nonce`, { uuid });
    if (data.status) {
      if (focused.addressIndex < 0) {
        return () => null;
      }
      const derived = rootKey.derivePath(generatePath(assets.code, focused.addressIndex));
      const { privateKey } = generateAddress(derived, focused.based);
      const signature = await provider[focused.based].eth.accounts.sign(
        provider[focused.based].utils.fromUtf8(`${data.nonce}`),
        privateKey
      );
      try {
        const { data: _data } = await axios.post(`${process.env.REACT_APP_HOST}/user/password`, {
          uuid,
          publicAddress: focused.walletAddress,
          signature,
          password: password.value,
          newPassword: newPassword.value,
        });
        if (_data.status) {
          enqueueSnackbar(t("비밀번호가 변경되었습니다."), { variant: "success" });
          history.goBack();
          return () => null;
        } else {
          switch (_data.code) {
            case "invalid_password": {
              return enqueueSnackbar(t("올바른 비밀번호가 아닙니다."), { variant: "error" });
            }
            default: {
              return enqueueSnackbar(t("서버 오류입니다. 잠시 후에 다시 시도하세요."), { variant: "error" });
            }
          }
        }
      } catch (e) {}
    }
  };

  React.useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <Page
      goBack
      invisibleRight
      title="Select Address"
      header
      subtitle={`${assets.mainnet ? `[Mainnet] ` : `[Testnet] `} ${capitalize(chainName(focused.based))}`}
    >
      {!loading ? (
        <React.Fragment>
          <Container
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              value={password.value}
              onChange={password.onChange}
              placeholder={"* " + t("현재 비밀번호")}
              fullWidth
              margin="dense"
              required={true}
              variant="outlined"
              type="password"
              sx={{ mb: 2 }}
            />

            <TextField
              size="small"
              value={newPassword.value}
              onChange={newPassword.onChange}
              placeholder={"* " + t("새 비밀번호")}
              fullWidth
              margin="dense"
              required={true}
              variant="outlined"
              type="password"
            />
            <TextField
              size="small"
              value={repeatNewPassword.value}
              onChange={repeatNewPassword.onChange}
              placeholder={"* " + t("새 비밀번호 확인")}
              fullWidth
              margin="dense"
              required={true}
              variant="outlined"
              type="password"
            />
            <Divider sx={{ mt: 2, mb: 2 }} />

            <Button
              onClick={passwordChange}
              color={newPassword.value.length >= 8 && repeatNewPassword.value.length >= 8 ? "primary" : "secondary"}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t("다음")}
            </Button>
          </Container>
        </React.Fragment>
      ) : (
        <Container
          sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <CircularProgress size={15} color="primary" />
        </Container>
      )}
    </Page>
  );
};

export default AddAssetView;
