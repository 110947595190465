import React from "react";
import { Typography, Box, ListItemButton, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import EmptyIcon from "resources/image/icon_empty.png";
import { symbolFactory } from "utils";
import { getBalance } from "utils";
import GlobalContext from "context/GlobalContext";

const AssetRow = (props) => {
  const item = props.item;
  const history = useHistory();
  const { provider, focused } = React.useContext(GlobalContext);
  const [balance, setBalance] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      setBalance(
        await getBalance({
          contractAddress: item.contractAddress,
          based: focused.based,
          provider,
          walletAddress: focused.walletAddress,
          nft: true,
        })
      );
    })();
  }, [props.isRefresing]);

  return (
    <ListItemButton
      onClick={
        balance === null
          ? () => null
          : () =>
              history.push({
                pathname: "/transfernft",
                state: {
                  contractAddress: item.contractAddress,
                  ...item,
                },
              })
      }
      sx={{ p: 0, mb: 1.5 }}
    >
      {balance === null ? (
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          py={1}
          px={2}
          sx={{ width: "100%", height: 60, boxShadow: "0px 0px 7.5px 1px rgba(0,0,0,0.3)" }}
        >
          <CircularProgress size={15} />
        </Box>
      ) : (
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="space-between"
          py={1}
          px={2}
          sx={{ width: "100%", height: 60, boxShadow: "0px 0px 7.5px 1px rgba(0,0,0,0.3)" }}
        >
          <Box display={"flex"} alignItems="center">
            <img style={{ width: 26 }} src={item.icon ? symbolFactory(item.icon) : EmptyIcon} />
            <Typography fontWeight={"bold"} lineHeight={1} fontSize={17} ml={1}>
              {item.ticker.toUpperCase()}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems="center">
            <Typography lineHeight={1} fontSize={17} ml={1}>
              {balance} {item.ticker.toUpperCase()}
            </Typography>
          </Box>
        </Box>
      )}
    </ListItemButton>
  );
};

export default AssetRow;
