import { useState } from "react";
import { Button, Container, TextField, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

import Page from "components/Common/Page";
import Dialog from "components/Common/Dialog";
import { useTranslation } from "react-i18next";
import useInput from "hooks/useInput";
import useDialog from "hooks/useDialog";
import { useLocation } from "react-router-dom";
import { useWeb3Auth } from "services/web3auth";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(7),
  },
  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
}));

const LoginView = () => {
  const {
    state: { type },
  } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const password = useInput("");
  const repeatPassword = useInput("");
  const recommend = useInput("PD5_6Tyvz");
  // const recommend = useInput("t_moron21");

  const [openDialog, dialogProps] = useDialog();
  const [dialogMessage, setDialogMessage] = useState("");
  const { login } = useWeb3Auth();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password.value !== repeatPassword.value) {
      setDialogMessage(t("두 비밀번호가 같지 않습니다."));
      openDialog();
      return () => null;
    }

    if (password.value.length < 8) {
      setDialogMessage(t("비밀번호는 8자리 이상이어야 합니다."));
      openDialog();
      return () => null;
    }

    if (recommend.value !== "") {
      const { data } = await axios.post(process.env.REACT_APP_HOST + "/authentication/recommend", {
        recommend: recommend.value,
      });
      if (!data.status) {
        setDialogMessage(t("올바르지 않은 추천인입니다."));
        openDialog();
        return () => null;
      }
    }

    if (type === "social") {
      return login({ pw: password.value, rec: recommend.value });
    }
    // return login({ pw: password.value, rec: recommend.value, main: false });
    const path = type === "generate" ? "/auth/register/mnemonic" : "/auth/load/mnemonic";
    const params = { password: password.value };
    history.push({ pathname: path, state: params });
  };

  return (
    <Page bottomTab={false} className={classes.root} title="" header goBack pb={1}>
      <Dialog {...dialogProps} title={t("지갑 생성 - 비밀번호 만들기")}>
        {dialogMessage}
      </Dialog>
      <form className={classes.form} onSubmit={onSubmit}>
        <Container maxWidth="xs">
          <Box mb={1}>
            <Typography color="textPrimary" variant="h2">
              {type === "load" && t("지갑 불러오기 - 비밀번호 만들기")}
              {type === "generate" && t("지갑 생성 - 비밀번호 만들기")}
              {type === "social" && t("원픽 월렛(전자지갑) 2차 비밀번호 만들기")}
            </Typography>
          </Box>

          {[
            { text: "원픽 전자지갑에서 거래시 반드시 필요한 비밀번호를 생성해야 합니다.", isRed: false },
            { text: "원픽 전자지갑 비밀번호는 소셜 네트워크 비밀번호와 다르게 설정해야 합니다.", isRed: false },
            { text: "비밀번호 생성시 8자리 이상이어야 합니다.", isRed: false },
            {
              text: "전자지갑의 특성상 비밀번호가 외부로 유출되거나 기억하지 못하는 경우 심각한 문제가 발생되므로 반드시 이점 숙지하시기 바랍니다.",
              isRed: true,
            },
            {
              text: "원픽다오에서는 개인의 부주의로 인한 비밀번호 유출과 분식에 대하여 일체의 책임을 지지않습니다.",
              isRed: true,
            },
          ].map((item, index, arr) => {
            return (
              <Typography
                gutterBottom={index === arr.length - 1}
                key={index + "typo"}
                color={item.isRed ? "red" : "textSecondary"}
                fontWeight={item.isRed ? "bold" : "normal"}
                variant="body2"
              >
                - {t(item.text)}
              </Typography>
            );
          })}

          <TextField
            size="small"
            value={password.value}
            onChange={password.onChange}
            placeholder={"* " + t("비밀번호")}
            fullWidth
            margin="dense"
            required={true}
            variant="outlined"
            type="password"
          />
          <TextField
            size="small"
            value={repeatPassword.value}
            onChange={repeatPassword.onChange}
            placeholder={"* " + t("비밀번호 확인")}
            fullWidth
            margin="dense"
            required={true}
            variant="outlined"
            type="password"
          />
          <TextField
            helperText={t(
              "추천인 코드를 반드시 기입하여야 합니다. 추천인 코드는 트리구조를 위해 필요하며, 추천인 코드가 없으면 원픽 플렛폼에서 이용에 제한이 있을 수 있습니다. 추천인 코드를 모르신다면, 추천인에게 문의 바랍니다."
            )}
            size="small"
            value={recommend.value}
            onChange={recommend.onChange}
            placeholder={"* " + t("추천 코드")}
            fullWidth
            required={true}
            margin="dense"
            variant="outlined"
          />
        </Container>
        <Container maxWidth="xs">
          <Button
            color={password.value.length >= 8 && repeatPassword.value.length >= 8 ? "primary" : "secondary"}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {t("다음")}
          </Button>
        </Container>
      </form>
    </Page>
  );
};

export default LoginView;
