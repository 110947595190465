import React, { useContext, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";

import Page from "components/Common/Page";
import { useTranslation } from "react-i18next";

import { useWeb3Auth } from "services/web3auth";
import GlobalContext from "context/GlobalContext";
import {
  capitalize,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Checkbox,
  Paper,
  Container,
} from "@mui/material";
import { chainName } from "utils";
import { useSnackbar } from "notistack";
import { generatePath, generateAddress, getBalance } from "utils";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";

const useclassNames = makeStyles((theme) => {
  return {
    monospace: {
      fontFamily: "monospace",
      fontSize: 13,
    },
    tableLoader: {
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      backgroundColor: "rgba(255,255,255,0.6)",
    },
    appbar: {
      width: "100%",
      backgroundColor: theme.palette.background.default,
      shadowOpacity: 0,
      elevation: 0,
    },
    container: {
      flex: 1,
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    captionWrapper: {
      width: "100%",
      padding: 10,
      textAlign: "left",
    },
    // caption: sheets.caption,
    // headerTitle: sheets.headerTitle,
    // headerSubTitle: sheets.headerSubTitle,
  };
});

const PAGE_ROW = 5;

const AddAssetView = () => {
  const classes = useclassNames();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const { provider, focused, setFocused, assets, rootKey } = React.useContext(GlobalContext);
  const [list, setList] = React.useState(null);
  const [page, setPage] = React.useState(parseInt(focused.addressIndex < 0 ? 0 : focused.addressIndex / PAGE_ROW));
  const [checked, setChecked] = React.useState(focused.addressIndex);
  const [loading, setLoading] = React.useState(false);

  const [listExternal, setListExternal] = React.useState(null);
  const [pageExternal, setPageExternal] = React.useState(0);
  const [countExternal, setCountExternal] = React.useState(null);
  const [loadingExternal, setLoadingExternal] = React.useState(false);

  const checkboxOnPress = async (item) => {
    if (item.index !== checked) {
      setLoading(true);
      const nonce = await provider[focused.based].eth.getTransactionCount(item.address, "pending");
      const newFocused = {
        ...focused,
        nonce,
        addressIndex: item.type === "wallet" ? item.index : item.index * -1,
        walletAddress: item.address,
      };

      const uuid = localStorage.getItem("wallet-uuid");
      const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/nonce`, { uuid });
      if (data.status) {
        if (focused.addressIndex < 0) {
          return () => null;
        }
        const derived = rootKey.derivePath(generatePath(assets.code, item.index));
        const { privateKey } = generateAddress(derived, focused.based);
        const signature = await provider[focused.based].eth.accounts.sign(
          provider[focused.based].utils.fromUtf8(`${data.nonce}`),
          privateKey
        );
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_HOST}/user/addrchange`, {
            uuid,
            publicAddress: item.address,
            signature,
          });
        } catch (e) {}
      } else {
        return enqueueSnackbar(t("이미 등록된 주소이거나 일시적인 오류입니다."));
      }

      localStorage.setItem("focused", JSON.stringify(newFocused));
      setChecked(item.type === "wallet" ? item.index : item.index * -1);
      setFocused(newFocused);

      enqueueSnackbar(
        t(
          "사용할 지갑 주소가 변경되었습니다." +
            " (" +
            item.address.substring(0, 8) +
            "..." +
            item.address.substring(item.address.length - 4, item.address.length) +
            ")"
        )
      );
      setLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let count = 0;
      const addressList = [];
      while (count < 5) {
        const index = count + page * PAGE_ROW;
        const derived = rootKey.derivePath(generatePath(assets.code, index));
        const { address } = generateAddress(derived, focused.based);
        const balance = await getBalance({
          based: focused.based,
          walletAddress: address,
          contractAddress: "",
          provider,
        });
        addressList.push({ address, index, balance });
        count++;
      }
      setList(addressList);
      setLoading(false);
    })();
  }, [page]);

  React.useEffect(() => {
    (async () => {
      const addressList = [];
      const body = {
        uuid: localStorage.getItem("wallet-uuid"),
        offset: pageExternal * PAGE_ROW,
        limit: PAGE_ROW,
      };
      const { data } = await axios.post(process.env.REACT_APP_HOST + "/contact/list", body);
      data.list.map((item, index) => {
        addressList.push({ address: item.wallet_address, index: item.rownum, balance: 0 });
      });

      const addressListBalance = await Promise.all(
        addressList.map(async (item) => {
          const balance = await getBalance({
            based: focused.based,
            walletAddress: item.address,
            contractAddress: "",
            provider,
          });

          return { ...item, balance };
        })
      );

      setCountExternal(data.count);
      setListExternal(addressListBalance);
      setLoadingExternal(false);
    })();
  }, [pageExternal]);
  return (
    <Page
      goBack
      invisibleRight
      title="Select Address"
      header
      subtitle={`${assets.mainnet ? `[Mainnet] ` : `[Testnet] `} ${capitalize(chainName(focused.based))}`}
    >
      {list && listExternal ? (
        <React.Fragment>
          <Paper sx={{ position: "relative", width: "100%" }}>
            {loading && (
              <Box className={classes.tableLoader}>
                <CircularProgress size={15} color="primary" />
              </Box>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ flex: 0.4 }}></TableCell>
                  <TableCell>Index</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>{capitalize(assets.ticker)} Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item) => {
                  return (
                    <TableRow
                      sx={{ "&:hover": { backgroundColor: "rgba(0,0,0,0.1)", cursor: "pointer" } }}
                      key={item.address + "wallet"}
                      onClick={checkboxOnPress.bind(null, { ...item, type: "wallet" })}
                    >
                      <TableCell sx={{ flex: 0.4, p: 0 }}>
                        <Checkbox disableRipple checked={checked === item.index} size={"small"} />
                      </TableCell>
                      <TableCell>{item.index}</TableCell>
                      <TableCell sx={{ flex: 1.5 }}>
                        <Typography className={classes.monospace}>
                          {item.address.substring(0, 4) +
                            "..." +
                            item.address.substring(item.address.length - 4, item.address.length)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.monospace}>{Number(Number(item.balance).toFixed(4))}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box p={1} display={"flex"} justifyContent={"flex-end"}>
              <IconButton
                disabled={page === 0}
                onClick={() => {
                  setLoading(true);
                  setPage((page) => page - 1);
                }}
              >
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setLoading(true);
                  setPage((page) => page + 1);
                }}
              >
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box className={classes.captionWrapper}>
              <Typography className={classes.caption}>
                {t("고유한 시드를 이용하여 여러 하위 지갑 주소를 생성할 수 있습니다.")}
              </Typography>
            </Box>
          </Paper>
          {/* <Paper sx={{ mt: 3, position: "relative", width: "100%" }}>
            {loading && (
              <Box className={classes.tableLoader}>
                <CircularProgress size={15} color="primary" />
              </Box>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ flex: 0.4 }}></TableCell>
                  <TableCell>Index</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>{capitalize(assets.ticker)} Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listExternal.map((item, index) => {
                  return (
                    <TableRow
                      sx={{ "&:hover": { backgroundColor: "rgba(0,0,0,0.1)", cursor: "pointer" } }}
                      key={item.address + "external" + index}
                      onClick={checkboxOnPress.bind(null, { ...item, type: "external" })}
                    >
                      <TableCell sx={{ flex: 0.4, p: 0 }}>
                        <Checkbox disableRipple checked={checked === item.index * -1} size={"small"} />
                      </TableCell>
                      <TableCell>{item.index}</TableCell>
                      <TableCell sx={{ flex: 1.5 }}>
                        <Typography className={classes.monospace}>
                          {item.address.substring(0, 4) +
                            "..." +
                            item.address.substring(item.address.length - 4, item.address.length)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.monospace}>{Number(Number(item.balance).toFixed(4))}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box p={1} display={"flex"} justifyContent={"flex-end"}>
              <IconButton
                disabled={pageExternal === 0}
                onClick={() => {
                  setLoadingExternal(true);
                  setPageExternal((page) => page - 1);
                }}
              >
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
              <IconButton
                disabled={countExternal < PAGE_ROW * (pageExternal + 1)}
                onClick={() => {
                  setLoadingExternal(true);
                  setPageExternal((page) => page + 1);
                }}
              >
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box className={classes.captionWrapper}>
              <Typography className={classes.caption}>{t("직접 추가한 외부 지갑주소 리스트입니다.")}</Typography>
            </Box>
          </Paper> */}
        </React.Fragment>
      ) : (
        <Container
          sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <CircularProgress size={15} color="primary" />
        </Container>
      )}
    </Page>
  );
};

export default AddAssetView;
