import React, { forwardRef, useContext } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import BottomNavigation from "@mui/material/BottomNavigation";
import Box from "@mui/material/Box";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { AccountBalanceWallet, MoreHoriz } from "@mui/icons-material";

import Header from "components/Common/Header";
import { makeStyles } from "@mui/styles";
import { useRouteMatch, useHistory } from "react-router-dom";
import GlobalContext from "context/GlobalContext";
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: theme.spacing(9),
  },
}));

const Page = forwardRef(
  (
    {
      isExternalLogin = false,
      invisibleRight = false,
      children,
      bottomTab = true,
      title = "",
      subtitle = "",
      refresh = false,
      network = false,
      header = false,
      goBack = false,
      menu = false,
      qr = false,
      invisible = false,
      pb = 9,
      ...rest
    },
    ref
  ) => {
    const { bottomNav, setBottomNav } = useContext(GlobalContext);
    const match = useRouteMatch();
    const history = useHistory();
    const classes = useStyles();
    const onCLickNav = (value) => {
      setBottomNav(value);
      if (match.path !== "/") {
        history.push("/");
      }
    };

    return (
      <div className={classes.root} ref={ref} {...rest}>
        <Helmet>
          <title>
            {process.env.REACT_APP_PROJECT_NAME} : {title}
          </title>
        </Helmet>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {header && (
            <Header
              invisibleRight={invisibleRight}
              invisible={invisible}
              title={title}
              subtitle={subtitle}
              refresh={refresh}
              network={network}
              goBack={goBack}
              menu={menu}
              qr={qr}
            />
          )}
          <Container
            sx={{ height: "100%", position: "absolute", overflow: "auto", pb: pb }}
            px={1}
            maxWidth={"xs"}
            display="flex"
            flexDirection="column"
            style={isExternalLogin ? { display: "flex", justifyContent: "center", alignItems: "center" } : {}}
          >
            {children}
          </Container>
        </Box>
        {bottomTab && (
          <BottomNavigation
            sx={{ width: "100%", position: "fixed", bottom: 0 }}
            value={bottomNav}
            // onChange={(event, newValue) => {
            //   setBottomNav(newValue);
            // }}
          >
            <BottomNavigationAction
              value={"wallet"}
              onClick={onCLickNav.bind(null, "wallet")}
              label="Wallet"
              icon={<AccountBalanceWallet />}
            />
            <BottomNavigationAction
              value={"more"}
              onClick={onCLickNav.bind(null, "more")}
              label="More"
              icon={<MoreHoriz />}
            />
          </BottomNavigation>
        )}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
